import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import React, { useRef } from 'react';
import { GrDocumentPdf } from 'react-icons/gr';

import { Tab, TabList, TabPanel } from 'react-tabs';
import { Bar, BarChart, CartesianGrid, Legend, ReferenceLine, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { useSystemContext } from '../../Context/SystemContext';
import tabelaImg from '../../assets/tabela.jpeg';
import Grid from '../../components/Grid';
import { Grid5W2H } from '../../components/Grid5W2H';
import { GridAnexos } from '../../components/GridAnexos';
import { GridDiario } from '../../components/GridDiario';
import { AttachmentInput, CheckBoxInput, DBSelectInput, DateInput, FormInput, GroupInput, Input, SelectInput, TabsInput, TextInput } from '../../components/Input';
import Print612 from '../../components/Print612';
import { dateToString } from '../../helper/dates';

const yup = require('../../interface/yup-br');

export const Form61 = () => {
    const System = useSystemContext();
    const riscos = [
        { value: 0, option: 'Desprezível' },
        { value: 1, option: 'Menor' },
        { value: 2, option: 'Moderado' },
        { value: 4, option: 'Sério' },
        { value: 8, option: 'Crítico' },
    ];

    return (
        <div className="mx-2">
            <TabsInput>
                <TabList>
                    <Tab>
                        <div className="inline-flex justify-center">
                            <div>🔷</div>
                        </div>
                    </Tab>
                    <Tab>
                        <div className="inline-flex justify-center">
                            <span>Gestão</span>
                        </div>
                    </Tab>
                </TabList>
                <TabPanel name="Principal">
                    <FormInput
                        document="/document/form61"
                        perms={'61'}
                        title={System.getMenuTitle(61)}
                        initialValues={{
                            conteudo: '',
                        }}
                        validationSchema={yup.object().shape({
                            conteudo: yup.string(),
                        })}
                        onRenderFields={() => {
                            return (
                                <div className="w-full">
                                    <TextInput name="conteudo" label="Conteúdo" className="w-full" rows="8" />
                                </div>
                            );
                        }}
                    />
                    <div className="ml-4">
                        <img src={tabelaImg} alt="Tabela" />
                    </div>
                </TabPanel>
                <TabPanel name="Risco">
                    <div className="w-full">
                        <Grid
                            url={'/griddocument/grid61'}
                            perms={'61'}
                            showPDFExport={true}
                            showCSVExport={true}
                            clearAutoEdit={true}
                            GridHeaders={[
                                { title: 'Requisito', field: 'requisito', sort: true, className: 'w-2/12 text-left pl-1' },
                                { title: 'Descrição do Risco', field: 'risco', sort: true, className: 'w-6/12 pl-1 text-left border-l border-gray-300' },
                                { title: 'Cadastro', field: 'cadastro', sort: true, className: 'w-1/12 pl-1 text-left border-l border-gray-300' },
                                { title: 'Atualização', field: 'atualizacao', sort: true, className: 'w-1/12 pl-1 text-left border-l border-gray-300' },
                                { title: 'Responsável', field: 'responsavel', sort: true, className: 'w-1/12 pl-1 text-left border-l border-gray-300' },
                                { title: 'Anexo', field: 'attachment', sort: false, className: 'w-1/12 pl-1 text-left border-l border-gray-300' },
                            ]}
                            LineDataPrint={(data) => {
                                return [
                                    data.requisito,
                                    data.descricaorisco,
                                    dateToString(data.cadastro),
                                    dateToString(data.atualizacao),
                                    data.crew?.nome,
                                    `#link=${data.attachment}`,
                                ];
                            }}
                            validationSchema={yup.object().shape({
                                requisito: yup.string(),
                                risco: yup.number(),
                                cadastro: yup.date(),
                                atualizacao: yup.date(),
                                responsavel: yup.string(),
                                attachment: yup.string(),
                                frequencia: yup.number(),
                                causas: yup.string(),
                                impactos: yup.string(),
                                acoes: yup.string(),
                            })}
                            onRenderForm={(GridModes, SetFieldValue, values) => {
                                function calcRisco(a, b) {
                                    let risco = Math.round((a * b) / 8);
                                    risco = (a * b) / 8 < 1 ? 0 : risco;
                                    risco = risco > 8 ? 8 : risco;
                                    SetFieldValue('risco', risco);
                                }
                                return (
                                    <div>
                                        <div className="inline-flex w-full h-8 py-1 pl-2 justify-left bg-amarelo-eq">
                                            <span>{!GridModes.insert_mode ? 'Editar' : 'Novo registro'}</span>
                                        </div>
                                        <Input name="requisito" label="Requisito" />
                                        <TextInput name="descricaorisco" label="Descrição do risco / oportunidade" rows={3} />
                                        <GroupInput>
                                            <SelectInput
                                                name="severidade"
                                                label="Severidade"
                                                className={'w-1/3'}
                                                onChange={(e) => {
                                                    SetFieldValue('severidade', e.target.value);
                                                    calcRisco(e.target.value, values.frequencia);
                                                }}
                                                listOptions={[
                                                    { value: 1, option: 'I' },
                                                    { value: 2, option: 'II' },
                                                    { value: 4, option: 'III' },
                                                    { value: 8, option: 'IV' },
                                                ]}
                                            />
                                            <SelectInput
                                                name="frequencia"
                                                label="Frequência"
                                                className={'w-1/3'}
                                                onChange={(e) => {
                                                    SetFieldValue('frequencia', e.target.value);
                                                    calcRisco(values.severidade, e.target.value);
                                                }}
                                                listOptions={[
                                                    { value: 1, option: 'A' },
                                                    { value: 2, option: 'B' },
                                                    { value: 4, option: 'C' },
                                                    { value: 8, option: 'D' },
                                                    { value: 16, option: 'E' },
                                                ]}
                                            />
                                            <SelectInput name="risco" label="Risco" className={'w-1/3'} disabled listOptions={riscos} />
                                        </GroupInput>
                                        <Input name="causas" label="Possível(is) Causa(s) ou Potencial(is)" />
                                        <Input name="controle" label="Controle(s) ou oportunidade(s) sobre a(s) possível(is) causa(s) e/ou no(s) possível(is) impacto(s)" />
                                        <GroupInput>
                                            <SelectInput
                                                name="significancia"
                                                label="Significância/Aceitabilidade"
                                                listOptions={[{ value: 'Sim para riscos moderados, sérios e críticos' }, { value: 'Não para riscos menores e desprezíveis' }]}
                                            />
                                        </GroupInput>
                                        <TextInput name="observacoes" label="Observações" rows={3} />
                                        <GroupInput>
                                            <DateInput name="cadastro" label="Cadastro/Atualização" />
                                            <DBSelectInput name="responsavel" label="Responsável" url={GridModes.insert_mode ? '/crewactive' : '/crew'} displaySelectField="nome" />
                                            <DateInput name="atualizacao" label="Próxima Atualização" />
                                        </GroupInput>
                                    </div>
                                );
                            }}
                            FormTabs={[
                                () => {
                                    return <div>Home</div>;
                                },
                                () => {
                                    return <div>5W2H</div>;
                                },
                                () => {
                                    return <div>Diário de Bordo</div>;
                                },
                                () => {
                                    return (
                                        <div className="inline-flex justify-center">
                                            <span>Anexos</span>
                                        </div>
                                    );
                                },
                            ]}
                            onRenderFormExtra={[
                                (GridModes, item, GridRefresh) => {
                                    return <Grid5W2H url={`/method5w2h/form61-${item._id}`} perms="61" />;
                                },
                                (GridModes, item, GridRefresh) => {
                                    return (
                                        <div>
                                            {!GridModes.insert_mode && (
                                                <div className={'m-2'}>
                                                    <GridDiario url={'/griddocument/grid61-' + item._id} perms={'61'} />
                                                </div>
                                            )}
                                        </div>
                                    );
                                },
                                (GridModes, item, GridRefresh) => {
                                    return (
                                        <FormInput
                                            document={`/griddocument/grid61/${item._id}`}
                                            perms={'61'}
                                            validationSchema={yup.object().shape({
                                                arquivo: yup.mixed(),
                                            })}
                                            onAfterSave={() => {
                                                GridRefresh();
                                            }}
                                            onRenderFields={(setFieldValue, values, callCommit) => {
                                                return <AttachmentInput name="attachment" perms="61" label="Anexo" callParentCommit={callCommit} />;
                                            }}
                                        />
                                    );
                                },
                            ]}
                            onBeforeDelete={async (item) => {
                                await System.apiDelete('/griddocument/grid61-' + item._id);
                                await System.apiDelete(`/method5w2h/form61-${item._id}`);
                            }}
                        />
                    </div>
                </TabPanel>
            </TabsInput>
        </div>
    );
};
export const Form612 = () => {
    const System = useSystemContext();
    const riscos = [
        { value: 0, option: 'Desprezível' },
        { value: 1, option: 'Menor' },
        { value: 2, option: 'Moderado' },
        { value: 4, option: 'Sério' },
        { value: 8, option: 'Crítico' },
    ];
    return (
        <div className="mx-2">
            <TabsInput>
                <TabList>
                    <Tab>
                        <div className="inline-flex justify-center">
                            <div>🔷</div>
                        </div>
                    </Tab>
                    <Tab>
                        <div className="inline-flex justify-center">
                            <span>Gestão</span>
                        </div>
                    </Tab>
                </TabList>
                <TabPanel name="Principal">
                    <FormInput
                        document="/document/form612"
                        perms={'612'}
                        title={System.getMenuTitle(612)}
                        initialValues={{
                            conteudo: '',
                        }}
                        validationSchema={yup.object().shape({
                            conteudo: yup.string(),
                        })}
                        onRenderFields={() => {
                            return (
                                <div className="w-full">
                                    <TextInput name="conteudo" label="Conteúdo" className="w-full" rows="8" />
                                </div>
                            );
                        }}
                    />
                    <div className="ml-4">
                        <img src={tabelaImg} alt="Tabela" />
                    </div>
                </TabPanel>
                <TabPanel name="Ambiental">
                    <Grid
                        url={'/griddocument/grid612'}
                        perms={'612'}
                        clearAutoEdit={true}
                        GridHeaders={[
                            { title: 'Área', field: 'area', sort: true, className: 'w-2/12 text-left pl-1' },
                            { title: 'Processo', field: 'processo', sort: true, className: 'w-7/12 pl-1 text-left border-l border-gray-300' },
                            { title: 'Responsável', field: 'responsavel', sort: true, className: 'w-2/12 pl-1 text-left border-l border-gray-300' },
                            { title: 'Cadastro', field: 'cadastro', sort: true, className: 'w-1/12 pl-1 text-left border-l border-gray-300' },
                        ]}
                        LineDataPrint={(data) => {
                            return [data.area, data.processo, data.crew?.nome, dateToString(data.cadastro)];
                        }}
                        validationSchema={yup.object().shape({
                            numero: yup.string(),
                            atualizacao: yup.string(),
                            cadastro: yup.string(),
                            processo: yup.string(),
                            area: yup.string(),
                            responsavel: yup.string(),
                            participantes: yup.string(),
                        })}
                        onRenderForm={(GridModes, SetFieldValue, values) => {
                            return (
                                <div>
                                    <div className="inline-flex w-full h-8 py-1 pl-2 justify-left bg-amarelo-eq">
                                        <span>{!GridModes.insert_mode ? 'Editar' : 'Novo registro'}</span>
                                    </div>
                                    <GroupInput>
                                        <Input name="numero" label="Número Documento" />
                                        <DateInput name="cadastro" label="Data Cadastro/Atualização" />
                                        <DateInput name="aproximaatualizacao" label="Próxima Atualização" />
                                    </GroupInput>
                                    <Input name="processo" label="Processo" />
                                    <Input name="area" label="Área" />
                                    <DBSelectInput name="responsavel" label="Responsável" url="/crewactive" displaySelectField="nome" />
                                    <Input name="participantes" label="Participantes" />
                                </div>
                            );
                        }}
                        FormTabs={[
                            () => {
                                return <div>Home</div>;
                            },
                            () => {
                                return <div>Detalhes</div>;
                            },
                            () => {
                                return <div>5W2H</div>;
                            },
                            () => {
                                return <div>Diário de Bordo</div>;
                            },
                            () => {
                                return <div>Impressão</div>;
                            },
                            () => {
                                return (
                                    <div className="inline-flex justify-center">
                                        <span>Anexos</span>
                                    </div>
                                );
                            },
                        ]}
                        onRenderFormExtra={[
                            (GridModes, item, GridRefresh) => {
                                // Detalhes
                                var url = `/griddocument/grid612-ambiental-${item._id}`;
                                return (
                                    <Grid
                                        url={url}
                                        perms={'612'}
                                        GridTitle={'Detalhes'}
                                        disableModal={true}
                                        GridHeaders={[
                                            { title: 'Tipo', field: 'tipo', sort: true, className: 'w-1/12 text-left pl-1' },
                                            { title: 'Aspecto/Perigo', field: 'aspecto', sort: true, className: 'w-2/12 pl-1 text-left border-l border-gray-300' },
                                            { title: 'Impacto/Dano', field: 'impacto', sort: true, className: 'w-8/12 pl-1 text-left border-l border-gray-300' },
                                            { title: 'Risco', field: 'risco', sort: true, className: 'w-1/12 pl-1 text-left border-l border-gray-300' },
                                        ]}
                                        LineDataPrint={(data) => {
                                            return [data.tipo, data.aspecto, data.impacto, riscos.find((x) => x.value === data.risco)?.option];
                                        }}
                                        validationSchema={yup.object().shape({
                                            aspecto: yup.string(),
                                            impacto: yup.string(),
                                            severidade: yup.string(),
                                            frequencia: yup.string(),
                                            risco: yup.string(),
                                            tipo: yup.string(),
                                            causas: yup.string(),
                                            controle: yup.string(),
                                            significancia: yup.string(),
                                        })}
                                        onRenderForm={(GridModes, SetFieldValue, values) => {
                                            function calcRisco(a, b) {
                                                let risco = Math.round((a * b) / 8);
                                                risco = (a * b) / 8 < 1 ? 0 : risco;
                                                risco = risco > 8 ? 8 : risco;
                                                SetFieldValue('risco', risco);
                                            }
                                            return (
                                                <div>
                                                    <div className="inline-flex w-full h-8 py-1 pl-2 justify-left bg-amarelo-eq">
                                                        <span>{!GridModes.insert_mode ? 'Editar' : 'Novo registro'}</span>
                                                    </div>
                                                    <GroupInput>
                                                        <Input name="aspecto" label="Descrição do Aspecto" />
                                                        <Input name="impacto" label="Descrição do Possível Impacto" />
                                                    </GroupInput>
                                                    <GroupInput>
                                                        <SelectInput
                                                            name="severidade"
                                                            label="Severidade"
                                                            className={'w-1/3'}
                                                            onChange={(e) => {
                                                                SetFieldValue('severidade', e.target.value);
                                                                calcRisco(e.target.value, values.frequencia);
                                                            }}
                                                            listOptions={[
                                                                { value: 1, option: 'I' },
                                                                { value: 2, option: 'II' },
                                                                { value: 4, option: 'III' },
                                                                { value: 8, option: 'IV' },
                                                            ]}
                                                        />
                                                        <SelectInput
                                                            name="frequencia"
                                                            label="Frequência"
                                                            className={'w-1/3'}
                                                            onChange={(e) => {
                                                                SetFieldValue('frequencia', e.target.value);
                                                                calcRisco(values.severidade, e.target.value);
                                                            }}
                                                            listOptions={[
                                                                { value: 1, option: 'A' },
                                                                { value: 2, option: 'B' },
                                                                { value: 4, option: 'C' },
                                                                { value: 8, option: 'D' },
                                                                { value: 16, option: 'E' },
                                                            ]}
                                                        />
                                                        <SelectInput name="risco" label="Risco" className={'w-1/3'} disabled listOptions={riscos} />
                                                    </GroupInput>
                                                    <GroupInput>
                                                        <SelectInput
                                                            name="tipo"
                                                            label="Tipo"
                                                            className={'w-1/3'}
                                                            listOptions={[{ value: 'Ambiental' }, { value: 'Ocupacional' }]}
                                                        />
                                                    </GroupInput>
                                                    <Input name="causas" label="Possível(is) Causa(s) ou Potencial(is)" />
                                                    <Input name="controle" label="Controle(s) sobre a(s) possível(is) causa(s) e/ou no(s) possível(is) impacto(s)" />
                                                    <GroupInput>
                                                        <SelectInput
                                                            name="significancia"
                                                            label="Significância/Aceitabilidade"
                                                            listOptions={[
                                                                { value: 'Sim para riscos moderados, sérios e críticos' },
                                                                { value: 'Não para riscos menores e desprezíveis' },
                                                            ]}
                                                        />
                                                    </GroupInput>
                                                </div>
                                            );
                                        }}
                                    />
                                );
                            },
                            (GridModes, item, GridRefresh) => {
                                // 5W2H
                                return <Grid5W2H url={`/method5w2h/form612-${item._id}`} perms="612" />;
                            },
                            (GridModes, item, GridRefresh) => {
                                // Diário de Bordo
                                return (
                                    <div>
                                        {!GridModes.insert_mode && (
                                            <div className={'m-2'}>
                                                <GridDiario url={'/griddocument/grid612-' + item._id} perms={'612'} />
                                            </div>
                                        )}
                                    </div>
                                );
                            },
                            (GridModes, item, GridRefresh) => {
                                // Impresão
                                return <Print612 item={item} />;
                            },
                            (GridModes, item, GridRefresh) => {
                                // Anexos
                                return <GridAnexos url={`/attachment/form612-${item._id}`} disableModal={true} perms="612" />;
                            },
                        ]}
                        onBeforeDelete={async (item) => {
                            await System.apiDelete('/griddocument/grid612-' + item._id);
                            await System.apiDelete(`/griddocument/grid612-ambiental-${item._id}`);
                            await System.apiDelete(`/method5w2h/form612-${item._id}`);
                            await System.apiDelete(`/attachment/form612-${item._id}`);
                        }}
                    />
                </TabPanel>
            </TabsInput>
        </div>
    );
};
export const Form613 = () => {
    const System = useSystemContext();
    return (
        <div className="mx-2">
            <TabsInput>
                <TabList>
                    <Tab>
                        <div className="inline-flex justify-center">
                            <div>🔷</div>
                        </div>
                    </Tab>
                    <Tab>
                        <div className="inline-flex justify-center">
                            <span>Gestão</span>
                        </div>
                    </Tab>
                </TabList>
                <TabPanel name="Principal">
                    <FormInput
                        document="/document/form613"
                        perms={'613'}
                        title={System.getMenuTitle(613)}
                        initialValues={{
                            conteudo: '',
                        }}
                        validationSchema={yup.object().shape({
                            conteudo: yup.string(),
                        })}
                        onRenderFields={() => {
                            return (
                                <div className="w-full">
                                    <TextInput name="conteudo" label="Conteúdo" className="w-full" rows="8" />
                                </div>
                            );
                        }}
                    />
                </TabPanel>
                <TabPanel name="Ambiental">
                    <Grid
                        url={'/griddocument/grid613'}
                        perms={'613'}
                        clearAutoEdit={true}
                        GridHeaders={[
                            { title: 'Tipo', field: 'tipo', sort: true, className: 'w-2/12 text-left pl-1' },
                            { title: 'Legislação', field: 'legislacao', sort: true, className: 'w-7/12 pl-1 text-left border-l border-gray-300' },
                            { title: 'Cadastro', field: 'cadastro', sort: true, className: 'w-1/12 pl-1 text-left border-l border-gray-300' },
                            { title: 'Responsável', field: 'responsavel', sort: true, className: 'w-1/12 pl-1 text-left border-l border-gray-300' },
                            { title: 'Situação', field: 'situacao', sort: true, className: 'w-1/12 pl-1 text-left border-l border-gray-300' },
                        ]}
                        LineDataPrint={(data) => {
                            return [data.tipo, data.legislacao, dateToString(data.cadastro), data.crew?.nome, data.situacao];
                        }}
                        onRenderForm={(GridModes, SetFieldValue, values) => {
                            return (
                                <div className={'m-2'}>
                                    <div className="inline-flex w-full h-8 py-1 pl-2 justify-left bg-amarelo-eq">
                                        <span>{!GridModes.insert_mode ? 'Editar' : 'Novo registro'}</span>
                                    </div>
                                    <SelectInput
                                        name="tipo"
                                        label="Tipo"
                                        className={'w-1/3'}
                                        listOptions={[
                                            { value: 'Meio Ambiente' },
                                            { value: 'Saúde e Segurança' },
                                            { value: 'Qualidade' },
                                            { value: 'Responsabilidade Social' },
                                            { value: 'Outros' },
                                        ]}
                                    />

                                    <TextInput name="legislacao" label="Legislação" rows={5} />
                                    <GroupInput>
                                        <SelectInput name="ambito" label="Âmbito" listOptions={[{ value: 'Federal' }, { value: 'Estadual' }, { value: 'Municipal' }]} />
                                        <Input name="orgao" label="Orgão" />
                                    </GroupInput>
                                    <TextInput name="ementa" label="Ementa" rows={5} />
                                    <Input name="tema" label="Tema" />
                                    <GroupInput>
                                        <DateInput name="cadastro" label="Cadastro/Ult.Atualização" />
                                        <DBSelectInput name="responsavel" label="Responsável" url={GridModes.insert_mode ? '/crewactive' : '/crew'} displaySelectField="nome" />
                                        <DateInput name="atualizacao" label="Próxima Atualização" />
                                    </GroupInput>
                                    <GroupInput>
                                        <SelectInput name="situacao" label="Situação" listOptions={[{ value: 'Ativo' }, { value: 'Inativo' }]} />
                                        <SelectInput
                                            name="frequencia"
                                            label="Frequência de Avaliação"
                                            listOptions={[{ value: 'Semestral' }, { value: 'Anual' }, { value: 'Bienal' }]}
                                        />
                                    </GroupInput>
                                </div>
                            );
                        }}
                        FormTabs={[
                            () => {
                                return <div>Home</div>;
                            },
                            () => {
                                return <div>Avaliações</div>;
                            },
                            () => {
                                return <div>5W2H</div>;
                            },
                            () => {
                                return <div>Diário de Bordo</div>;
                            },
                            () => {
                                return (
                                    <div className="inline-flex justify-center">
                                        <span>Anexos</span>
                                    </div>
                                );
                            },
                        ]}
                        onRenderFormExtra={[
                            (GridModes, item, GridRefresh) => {
                                // Avaliações
                                var url = `/griddocument/grid613-avaliacoes-${item._id}`;
                                return (
                                    <div className={'m-2'}>
                                        <Grid
                                            url={url}
                                            perms={'613'}
                                            GridTitle={'Avaliações'}
                                            disableModal={true}
                                            GridHeaders={[
                                                { title: 'Data Avaliação', field: 'data', sort: true, className: 'w-1/6 text-left pl-1' },
                                                { title: 'Responsável', field: 'responsavel', sort: true, className: 'w-3/6 pl-1 text-left border-l border-gray-300' },
                                                { title: 'Status', field: 'status', sort: true, className: 'w-1/6 pl-1 text-left border-l border-gray-300' },
                                                { title: 'Próx. Avaliação', field: 'proxavaliacao', sort: true, className: 'w-1/6 pl-1 text-left border-l border-gray-300' },
                                            ]}
                                            LineDataPrint={(data) => {
                                                return [dateToString(data.data), data.crew?.nome, data.status, dateToString(data.proxavaliacao)];
                                            }}
                                            onRenderForm={(GridModes, SetFieldValue, values) => {
                                                return (
                                                    <div>
                                                        <DateInput name="data" label="Data da Avaliação" className={'inline-block'} />
                                                        <TextInput name="evidencia" label="Evidência de atendimento" rows={5} />
                                                        <DateInput name="proxavaliacao" label="Próxima Avaliação" className={'inline-block'} />
                                                        <DBSelectInput
                                                            name="responsavel"
                                                            label="Avaliador"
                                                            url={GridModes.insert_mode ? '/crewactive' : '/crew'}
                                                            displaySelectField="nome"
                                                        />
                                                        <SelectInput
                                                            name="status"
                                                            label="Status"
                                                            className={'inline-block'}
                                                            listOptions={[{ value: 'Conforme' }, { value: 'Não conforme' }]}
                                                        />
                                                    </div>
                                                );
                                            }}
                                            validationSchema={yup.object().shape({
                                                data: yup.string(),
                                                evidencia: yup.string(),
                                                proxavaliacao: yup.string(),
                                                responsavel: yup.string(),
                                                status: yup.string(),
                                            })}
                                        />
                                    </div>
                                );
                            },
                            (GridModes, item, GridRefresh) => {
                                // 5W2H
                                return (
                                    <div className={'m-2'}>
                                        <Grid5W2H url={`/method5w2h/form613-${item._id}`} perms="613" />
                                    </div>
                                );
                            },
                            (GridModes, item) => {
                                // Diario de bordo
                                return (
                                    <div>
                                        {!GridModes.insert_mode && (
                                            <div className={'m-2'}>
                                                <GridDiario url={'/griddocument/grid613-' + item._id} perms={'613'} />
                                            </div>
                                        )}
                                    </div>
                                );
                            },
                            (GridModes, item) => {
                                // Anexos
                                return (
                                    <div className={'m-2'}>
                                        <GridAnexos url={'/attachment/form613-' + item._id} disableModal={true} perms="613" />
                                    </div>
                                );
                            },
                        ]}
                        onBeforeDelete={async (item) => {
                            await System.apiDelete('/attachment/form613-' + item._id);
                            await System.apiDelete('/griddocument/grid613-' + item._id);
                            await System.apiDelete(`/method5w2h/form613-${item._id}`);
                            await System.apiDelete(`/griddocument/grid613-avaliacoes-${item._id}`);
                        }}
                        validationSchema={yup.object().shape({
                            tipo: yup.string().required(),
                            legislacao: yup.string(),
                            ambito: yup.string(),
                            orgao: yup.string(),
                            ementa: yup.string(),
                            tema: yup.string(),
                            cadastro: yup.string(),
                            atualizacao: yup.string(),
                            responsavel: yup.string(),
                            situacao: yup.string(),
                        })}
                    />
                </TabPanel>
            </TabsInput>
        </div>
    );
};
export const Form614 = () => {
    const System = useSystemContext();
    return (
        <div>
            <FormInput
                document="/document/form614"
                perms={'614'}
                title={System.getMenuTitle(614)}
                initialValues={{
                    conteudo: '',
                }}
                validationSchema={yup.object().shape({
                    conteudo: yup.string(),
                })}
                onRenderFields={() => {
                    return (
                        <div className="w-full">
                            <TextInput name="conteudo" label="Conteúdo" className="w-full" rows="8" />
                        </div>
                    );
                }}
            />
        </div>
    );
};
export const Form62 = () => {
    const System = useSystemContext();
    const chartRef = useRef(null);

    function toFloat(value) {
        if (value) return parseFloat(value);
        return 0;
    }
    function calcMedia(values) {
        function haveSameValue(value) {
            if (value === undefined) return false;
            if (value === null) return false;
            if (value === '') return false;
            if (toFloat(value) >= 0) return true;
            return false;
        }

        let divisor = 0;
        let adivisor = 0;

        switch (values.frequencia) {
            case 'Mensal':
                // atual
                for (let i = 1; i <= 12; i++) {
                    if (haveSameValue(values?.[`valor${i}`])) divisor += 1;
                    if (haveSameValue(values?.[`avalor${i}`])) adivisor += 1;
                }

                let atual = 0;
                for (let i = 1; i <= 12; i++) {
                    atual += toFloat(values[`valor${i}`]);
                }
                atual /= divisor;
                let anterior = 0;
                for (let i = 1; i <= 12; i++) {
                    anterior += toFloat(values[`avalor${i}`]);
                }
                anterior /= adivisor;
                return { Anterior: anterior.toFixed(2), Atual: atual.toFixed(2), label: 'Média' };
            case 'Bimestral':
                atual = (toFloat(values.valor1) + toFloat(values.valor2)) / 2;
                anterior = (toFloat(values.avalor1) + toFloat(values.avalor2)) / 2;
                return { Anterior: anterior.toFixed(2), Atual: atual.toFixed(2), label: 'Média' };
            case 'Trimestral':
                atual = (toFloat(values.valor1) + toFloat(values.valor2) + toFloat(values.valor3)) / 3;
                anterior = (toFloat(values.avalor1) + toFloat(values.avalor2) + toFloat(values.avalor3)) / 3;
                return { Anterior: anterior.toFixed(2), Atual: atual.toFixed(2), label: 'Média' };
            case 'Semestral':
                atual = (toFloat(values.valor1) + toFloat(values.valor2)) / 2;
                anterior = (toFloat(values.avalor1) + toFloat(values.avalor2)) / 2;
                return { Anterior: anterior.toFixed(2), Atual: atual.toFixed(2), label: 'Média' };
            default:
                return { Anterior: 0, Atual: 0, label: 'Média' };
        }
    }
    function mountBars(values) {
        let ret = [];
        if (values.frequencia === 'Mensal') {
            ret = [
                { Anterior: values.avalor1, Atual: values.valor1, label: 'Jan' },
                { Anterior: values.avalor2, Atual: values.valor2, label: 'Fev' },
                { Anterior: values.avalor3, Atual: values.valor3, label: 'Mar' },
                { Anterior: values.avalor4, Atual: values.valor4, label: 'Abr' },
                { Anterior: values.avalor5, Atual: values.valor5, label: 'Mai' },
                { Anterior: values.avalor6, Atual: values.valor6, label: 'Jun' },
                { Anterior: values.avalor7, Atual: values.valor7, label: 'Jul' },
                { Anterior: values.avalor8, Atual: values.valor8, label: 'Ago' },
                { Anterior: values.avalor9, Atual: values.valor9, label: 'Set' },
                { Anterior: values.avalor10, Atual: values.valor10, label: 'Out' },
                { Anterior: values.avalor11, Atual: values.valor11, label: 'Nov' },
                { Anterior: values.avalor12, Atual: values.valor12, label: 'Dez' },
                calcMedia(values),
            ];
        }
        if (values.frequencia === 'Bimestral')
            ret = [
                { Anterior: values.avalor1, Atual: values.valor1, label: '1º Bim' },
                { Anterior: values.avalor2, Atual: values.valor2, label: '2º Bim' },
                { Anterior: values.avalor3, Atual: values.valor3, label: '3º Bim' },
                { Anterior: values.avalor4, Atual: values.valor4, label: '4º Bim' },
                { Anterior: values.avalor5, Atual: values.valor5, label: '5º Bim' },
                { Anterior: values.avalor6, Atual: values.valor6, label: '6º Bim' },
                calcMedia(values),
            ];
        if (values.frequencia === 'Trimestral')
            ret = [
                { Anterior: values.avalor1, Atual: values.valor1, label: '1º Tri' },
                { Anterior: values.avalor2, Atual: values.valor2, label: '2º Tri' },
                { Anterior: values.avalor3, Atual: values.valor3, label: '3º Tri' },
                { Anterior: values.avalor4, Atual: values.valor4, label: '4º Tri' },
                calcMedia(values),
            ];
        if (values.frequencia === 'Semestral')
            ret = [{ Anterior: values.avalor1, Atual: values.valor1, label: '1º Sem' }, { Anterior: values.avalor2, Atual: values.valor2, label: '2º Sem' }, calcMedia(values)];
        if (values.frequencia === 'Anual') ret = [{ Anterior: values.avalor1, Atual: values.valor1, label: 'Ano' }];
        return ret;
    }

    async function ChartToPDF(values) {
        const chartNode = chartRef.current;
        const canvas = await html2canvas(chartNode);
        let data = mountBars(values);

        const pdf = new jsPDF('landscape');
        pdf.text(`${values.descricao}`, 20, 20);
        pdf.addImage(canvas.toDataURL('image/png'), 'PNG', 10, 25, 220, 100); // Insira a imagem do gráfico
        pdf.setFontSize(12);
        pdf.text('Anterior:', 10, 146);
        pdf.text('Atual:', 14, 152);
        let hz = 30;
        data.forEach((item, index) => {
            pdf.text(`${item.label}`, hz, 140);
            pdf.text(`${item.Anterior || '---'}`, hz, 146);
            pdf.text(`${item.Atual || '---'}`, hz, 152);
            hz += 14;
        });
        // Baixe ou exiba o PDF
        pdf.save('Grafico.pdf');
    }

    return (
        <div className="mx-2">
            <TabsInput>
                <TabList>
                    <Tab>
                        <div className="inline-flex justify-center">
                            <div>🔷</div>
                        </div>
                    </Tab>
                    <Tab>
                        <div className="inline-flex justify-center">
                            <span>Gestão</span>
                        </div>
                    </Tab>
                </TabList>
                <TabPanel name="Principal">
                    <FormInput
                        document="/document/form62"
                        perms={'62'}
                        title={System.getMenuTitle(62)}
                        initialValues={{
                            conteudo: '',
                        }}
                        validationSchema={yup.object().shape({
                            conteudo: yup.string(),
                        })}
                        onRenderFields={() => {
                            return (
                                <div className="w-full">
                                    <TextInput name="conteudo" label="Conteúdo" className="w-full" rows="8" />
                                </div>
                            );
                        }}
                    />
                </TabPanel>
                <TabPanel name="Objectivos">
                    <Grid
                        url={'/griddocument/grid62'}
                        perms={'62'}
                        clearAutoEdit={true}
                        showPDFExport={true}
                        showCSVExport={true}
                        disableModal={true}
                        modalHeight={'55%'}
                        modalWidth={'60%'}
                        modalMargin={'5% auto'}
                        GridHeaders={[
                            { title: 'Descrição', field: 'descricao', sort: true, className: 'w-2/4 text-left pl-1' },
                            { title: 'Etapa', field: 'etapa', sort: true, className: 'w-1/6 pl-1 text-left border-l border-gray-300' },
                            { title: 'Frequência', field: 'frequencia', sort: true, className: 'w-1/6 w-32 pl-1 text-left border-l border-gray-300' },
                            { title: 'Responsável', field: 'responsavel', sort: true, className: 'w-1/4 pl-1 text-left border-l border-gray-300' },
                        ]}
                        LineDataPrint={(data) => {
                            return [data.descricao.substr(0, 50), data.etapa, data.frequencia, data.crew?.nome];
                        }}
                        onRenderForm={(GridModes, SetFieldValue, values) => {
                            return (
                                <div>
                                    <div className="inline-flex w-full h-8 py-1 pl-2 justify-left bg-amarelo-eq">
                                        <span>{!GridModes.insert_mode ? 'Editar' : 'Novo registro'}</span>
                                    </div>
                                    <Input name="descricao" label="Descrição" className="w-full" rows="8" />
                                    <Input name="etapa" label="Etapa" />
                                    <DBSelectInput name="responsavel" label="Responsável" url={GridModes.insert_mode ? '/crewactive' : '/crew'} displaySelectField="nome" />
                                    <GroupInput>
                                        <SelectInput
                                            name="frequencia"
                                            label="Frequência"
                                            className={'w-1/4'}
                                            listOptions={[{ value: 'Mensal' }, { value: 'Bimestral' }, { value: 'Trimestral' }, { value: 'Semestral' }, { value: 'Anual' }]}
                                        />
                                        <SelectInput
                                            name="operacao"
                                            label="Operação"
                                            className={'w-1/3'}
                                            listOptions={[
                                                { value: 'lt', option: '< (Menor quê)' },
                                                { value: 'le', option: '<= (Menor ou igual)' },
                                                { value: 'eq', option: '= (Igual)' },
                                                { value: 'ge', option: '>= (Maior ou igual)' },
                                                { value: 'gt', option: '> (Maior quê)' },
                                            ]}
                                        />
                                        <Input name="meta" label="Meta" className={'w-44'} />
                                    </GroupInput>
                                    <Input name="unidade" label="Unidade" />
                                    <TextInput name="memoria" label="Memória de Cálculo" className="w-full" rows="8" />
                                </div>
                            );
                        }}
                        validationSchema={yup.object().shape({
                            descricao: yup.string().required(),
                            etapa: yup.string(),
                            responsavel: yup.string(),
                            frequencia: yup.string(),
                            operacao: yup.string(),
                            meta: yup.string(),
                            unidade: yup.string(),
                            memoria: yup.string(),
                        })}
                        FormTabs={[
                            () => {
                                return <div>Home</div>;
                            },
                            () => {
                                return <div>Gráfico</div>;
                            },
                            () => {
                                return <div>5W2H</div>;
                            },
                            () => {
                                return <div>Diário de bordo</div>;
                            },
                            () => {
                                return <div>Análise e Avaliação</div>;
                            },
                            () => {
                                return <div>Anexos</div>;
                            },
                        ]}
                        onRenderFormExtra={[
                            // Grafico
                            (GridModes, item, GridRefresh) => {
                                return (
                                    <div>
                                        <FormInput
                                            document={`/griddocument/grid62/${item._id}`}
                                            perms={'62'}
                                            title="Gráfico"
                                            onRenderFields={(setFieldValue, values) => {
                                                return (
                                                    <div>
                                                        <GroupInput>
                                                            <div className="mt-8 ml-2 w-36">Anterior</div>
                                                            {values.frequencia === 'Mensal' && (
                                                                <div className="inline-flex ml-4">
                                                                    <Input name="avalor1" label="Jan" />
                                                                    <Input name="avalor2" label="Fev" />
                                                                    <Input name="avalor3" label="Mar" />
                                                                    <Input name="avalor4" label="Abr" />
                                                                    <Input name="avalor5" label="Mai" />
                                                                    <Input name="avalor6" label="Jun" />
                                                                    <Input name="avalor7" label="Jul" />
                                                                    <Input name="avalor8" label="Ago" />
                                                                    <Input name="avalor9" label="Set" />
                                                                    <Input name="avalor10" label="Out" />
                                                                    <Input name="avalor11" label="Nov" />
                                                                    <Input name="avalor12" label="Dez" />
                                                                </div>
                                                            )}
                                                            {values.frequencia === 'Bimestral' && (
                                                                <>
                                                                    <Input name="avalor1" label="1º Bimestre" />
                                                                    <Input name="avalor2" label="2º Bimestre" />
                                                                    <Input name="avalor3" label="3º Bimestre" />
                                                                    <Input name="avalor4" label="4º Bimestre" />
                                                                    <Input name="avalor5" label="5º Bimestre" />
                                                                    <Input name="avalor6" label="6º Bimestre" />
                                                                </>
                                                            )}
                                                            {values.frequencia === 'Trimestral' && (
                                                                <>
                                                                    <Input name="avalor1" label="1º Trimestre" />
                                                                    <Input name="avalor2" label="2º Trimestre" />
                                                                    <Input name="avalor3" label="3º Trimestre" />
                                                                    <Input name="avalor4" label="4º Trimestre" />
                                                                </>
                                                            )}
                                                            {values.frequencia === 'Semestral' && (
                                                                <>
                                                                    <Input name="avalor1" label="1º Semestre" />
                                                                    <Input name="avalor2" label="2º Semestre" />
                                                                </>
                                                            )}
                                                            {values.frequencia === 'Anual' && (
                                                                <>
                                                                    <Input name="avalor1" label="Anual" />
                                                                </>
                                                            )}
                                                        </GroupInput>
                                                        <GroupInput>
                                                            <div className="pl-5 mt-8 w-36">Atual</div>
                                                            {values.frequencia === 'Mensal' && (
                                                                <div className="inline-flex ml-4">
                                                                    <Input name="valor1" label="Jan" />
                                                                    <Input name="valor2" label="Fev" />
                                                                    <Input name="valor3" label="Mar" />
                                                                    <Input name="valor4" label="Abr" />
                                                                    <Input name="valor5" label="Mai" />
                                                                    <Input name="valor6" label="Jun" />
                                                                    <Input name="valor7" label="Jul" />
                                                                    <Input name="valor8" label="Ago" />
                                                                    <Input name="valor9" label="Set" />
                                                                    <Input name="valor10" label="Out" />
                                                                    <Input name="valor11" label="Nov" />
                                                                    <Input name="valor12" label="Dez" />
                                                                </div>
                                                            )}
                                                            {values.frequencia === 'Bimestral' && (
                                                                <>
                                                                    <Input name="valor1" label="1º Bimestre" />
                                                                    <Input name="valor2" label="2º Bimestre" />
                                                                    <Input name="valor3" label="3º Bimestre" />
                                                                    <Input name="valor4" label="4º Bimestre" />
                                                                    <Input name="valor5" label="5º Bimestre" />
                                                                    <Input name="valor6" label="6º Bimestre" />
                                                                </>
                                                            )}
                                                            {values.frequencia === 'Trimestral' && (
                                                                <>
                                                                    <Input name="valor1" label="1º Trimestre" />
                                                                    <Input name="valor2" label="2º Trimestre" />
                                                                    <Input name="valor3" label="3º Trimestre" />
                                                                    <Input name="valor4" label="4º Trimestre" />
                                                                </>
                                                            )}
                                                            {values.frequencia === 'Semestral' && (
                                                                <>
                                                                    <Input name="valor1" label="1º Semestre" />
                                                                    <Input name="valor2" label="2º Semestre" />
                                                                </>
                                                            )}
                                                            {values.frequencia === 'Anual' && (
                                                                <>
                                                                    <Input name="valor1" label="Anual" />
                                                                </>
                                                            )}
                                                        </GroupInput>
                                                        <div className="w-full h-96" ref={chartRef}>
                                                            <ResponsiveContainer width="100%" height="100%">
                                                                <BarChart
                                                                    width={500}
                                                                    height={300}
                                                                    data={mountBars(values)}
                                                                    margin={{
                                                                        top: 5,
                                                                        right: 30,
                                                                        left: 20,
                                                                        bottom: 5,
                                                                    }}
                                                                >
                                                                    <CartesianGrid strokeDasharray="3 3" />
                                                                    <XAxis dataKey="label" />
                                                                    <YAxis domain={[0, dataMax => (Math.ceil(dataMax * 2) / 10) * 10]} />
                                                                    <Tooltip />
                                                                    <Legend />

                                                                    <Bar dataKey="Anterior" legendType="none" fill="#83af83" />
                                                                    <Bar dataKey="Atual" legendType="none" fill="#93c6f9" />
                                                                    <ReferenceLine
                                                                        y={values.meta}
                                                                        stroke="black"
                                                                        strokeDasharray="5 5"
                                                                        label={{ value: `Meta - ${values.meta}`, position: 'top' }}
                                                                        ifOverflow="extendDomain"
                                                                    />
                                                                </BarChart>
                                                            </ResponsiveContainer>
                                                        </div>
                                                        <GroupInput>
                                                            <button
                                                                type="submit"
                                                                className="w-12 h-10 ml-4 rounded-md shadow-md bg-botao-primario text-botao-primario-fonte hover:bg-botao-primario-hover hover:text-botao-primario-fonte"
                                                                title="Exportar para PDF"
                                                                onClick={() => {
                                                                    ChartToPDF(values);
                                                                }}
                                                            >
                                                                <GrDocumentPdf className="ml-4 -mt-2 h-14" />
                                                            </button>
                                                        </GroupInput>
                                                    </div>
                                                );
                                            }}
                                            validationSchema={yup.object().shape({})}
                                        />
                                    </div>
                                );
                            },
                            // 5W2H
                            (GridModes, item, GridRefresh) => {
                                return (
                                    <div>
                                        <Grid5W2H url={`/method5w2h/form62-${item._id}`} perms="62" />
                                    </div>
                                );
                            },
                            // Diário de bordo
                            (GridModes, item, GridRefresh) => {
                                return (
                                    <div>
                                        {!GridModes.insert_mode && (
                                            <div>
                                                <GridDiario url={'/griddocument/grid62-' + item._id} perms={'62'} />
                                            </div>
                                        )}
                                    </div>
                                );
                            },
                            // Análise e Avaliação
                            (GridModes, item, GridRefresh) => {
                                return (
                                    <div className={'m-2'}>
                                        <Grid
                                            url={`/griddocument/grid62-analiseavaliacao-${item._id}`}
                                            perms={'62'}
                                            disableModal={true}
                                            GridHeaders={[
                                                { title: 'Data', field: 'data', sort: true, className: 'w-1/6 text-left pl-1' },
                                                { title: 'Período avaliado', field: 'periodo', sort: false, className: 'w-4/6 pl-1 text-left border-l border-gray-300' },
                                            ]}
                                            LineDataPrint={(data) => {
                                                return [dateToString(data.data), data.periodo];
                                            }}
                                            onRenderForm={(GridModes, SetFieldValue, values) => {
                                                return (
                                                    <div>
                                                        <DateInput name="data" label="Data" className={'inline-block'} />
                                                        <Input name="periodo" label="Período avaliado" />
                                                        <TextInput name="registro" label="Registro" rows={'5'} />
                                                    </div>
                                                );
                                            }}
                                            validationSchema={() => {
                                                return yup.object().shape({
                                                    data: yup.date(),
                                                });
                                            }}
                                        />
                                    </div>
                                );
                            },
                            // Anexos
                            (GridModes, item, GridRefresh) => {
                                return (
                                    <div>
                                        <GridAnexos url={`/attachment/form62-${item._id}`} disableModal={true} perms="62" />
                                    </div>
                                );
                            },
                        ]}
                        onBeforeDelete={async (item) => {
                            await System.apiDelete(`/method5w2h/form62-${item._id}`);
                            await System.apiDelete(`/attachment/form62-${item._id}`);
                            await System.apiDelete(`/griddocument/grid62-${item._id}`);
                        }}
                    />
                </TabPanel>
            </TabsInput>
        </div>
    );
};
export const Form63 = () => {
    const System = useSystemContext();
    const gridRef = useRef(null);
    return (
        <div className="mx-2">
            <TabsInput>
                <TabList>
                    <Tab>
                        <div className="inline-flex justify-center">
                            <div>🔷</div>
                        </div>
                    </Tab>
                    <Tab>
                        <div className="inline-flex justify-center">
                            <span>Gestão</span>
                        </div>
                    </Tab>
                </TabList>
                <TabPanel name="Principal">
                    <FormInput
                        document="/document/form63"
                        perms={'63'}
                        title={System.getMenuTitle(63)}
                        initialValues={{
                            conteudo: '',
                        }}
                        validationSchema={yup.object().shape({
                            conteudo: yup.string(),
                        })}
                        onRenderFields={() => {
                            return (
                                <div className="w-full">
                                    <TextInput name="conteudo" label="Conteúdo" className="w-full" rows="8" />
                                </div>
                            );
                        }}
                    />
                </TabPanel>
                <TabPanel name="Ambiental">
                    <Grid
                        url={'/griddocument/grid63'}
                        perms={'63'}
                        clearAutoEdit={true}
                        externalRef={gridRef}
                        hideIndexColumn={true}
                        onBeforeInsertAction={(item) => {
                            const GridData = gridRef.current.methods.GridData();
                            let maiorNumero = GridData.reduce((maior, item) => {
                                const valor = parseInt(item.numero);
                                return valor > maior && Number.isInteger(valor) ? valor : maior;
                            }, 0);
                            maiorNumero += 1;
                            item.numero = maiorNumero.toString();
                            return item;
                        }}
                        GridHeaders={[
                            { title: 'Número', field: 'numero', sort: true, className: 'w-1/12 text-left pl-1' },
                            { title: 'Data', field: 'data', sort: true, className: 'w-1/12 text-left pl-1' },
                            { title: 'Descrição da Mudança', field: 'descricao', sort: true, className: 'w-6/12 pl-1 text-left border-l border-gray-300' },
                            { title: 'Tipo', field: 'tipo', sort: true, className: 'w-1/12 pl-1 text-left border-l border-gray-300' },
                            { title: 'Responsável', field: 'crew.nome', sort: true, className: 'w-4/12 pl-1 text-left border-l border-gray-300' },
                        ]}
                        LineDataPrint={(data) => {
                            return [data.numero, dateToString(data.data), data.descricao, data.tipo, data.crew?.nome];
                        }}
                        onRenderForm={(GridModes, SetFieldValue, values) => {
                            return (
                                <div>
                                    <div className="inline-flex w-full h-8 py-1 pl-2 justify-left bg-amarelo-eq">
                                        <span>{!GridModes.insert_mode ? 'Editar' : 'Novo registro'}</span>
                                    </div>
                                    <GroupInput>
                                        <Input name="numero" label="Número" className="w-1/6" />
                                    </GroupInput>

                                    <GroupInput>
                                        <DateInput name="data" label="Data" className={'inline-block'} />
                                        <SelectInput
                                            name="mudanca"
                                            label="Mudança"
                                            listOptions={[
                                                { value: 'Sistema de Gestão' },
                                                { value: 'Pessoas' },
                                                { value: 'Novo Produto' },
                                                { value: 'Novo Processo' },
                                                { value: 'Novo Serviço' },
                                                { value: 'Risco' },
                                                { value: 'Oportunidade' },
                                                { value: 'Mudança de requisito legal' },
                                                { value: 'Novo conhecimento ou tecnologia' },
                                                { value: 'Mudança em aspecto/impacto ambiental' },
                                                { value: 'Mudança em perigo/risco ocupacional' },
                                                { value: 'Alteração de Produto, Processo ou Serviço' },
                                                { value: 'Alteração da Razão Social/ Nome Fantasia' },
                                                { value: 'Alteração de endereço' },
                                                { value: 'Alteração do Representante Legal ou quadro societário' },
                                                { value: 'Outros' },
                                            ]}
                                        />
                                    </GroupInput>
                                    <GroupInput>
                                        <SelectInput name="tipo" label="Tipo" className={'w-48'} listOptions={[{ value: 'Permanente' }, { value: 'Temporário' }]} />
                                        <Input name="local" label="Local da mudança" />
                                    </GroupInput>
                                    <TextInput name="descricao" label="Descrição da mudança" rows={4} />
                                    <CheckBoxInput name="avaliacao" label="Mudança na avaliação de riscos e oportunidades" />
                                    <CheckBoxInput name="requisito" label="Mudança na identificação de requisito legal ou outro requisito" />
                                    <CheckBoxInput name="aspecto" label="Mudança na identificação de aspecto/impacto ambiental ou ocupacional" />
                                    <CheckBoxInput name="comunicacao" label="Necessidade de comunicação interna ou externa" />
                                    <CheckBoxInput name="alteracao" label="Necessidade de alteração do programa de auditoria interna" />
                                    <TextInput name="participantes" label="Participantes da análise" rows={4} />
                                    <GroupInput>
                                        <DateInput name="dataanalise" label="Data da análise" className={'inline-block'} />
                                        <DBSelectInput
                                            name="responsavel"
                                            label="Responsável pela aprovação da mudança"
                                            url={GridModes.insert_mode ? '/crewactive' : '/crew'}
                                            displaySelectField="nome"
                                        />
                                    </GroupInput>
                                </div>
                            );
                        }}
                        FormTabs={[
                            () => {
                                return <div>Home</div>;
                            },
                            () => {
                                return <div>5W2H</div>;
                            },
                            () => {
                                return <div>Diário de Bordo</div>;
                            },
                            () => {
                                return (
                                    <div className="inline-flex justify-center">
                                        <span>Anexos</span>
                                    </div>
                                );
                            },
                        ]}
                        onRenderFormExtra={[
                            (GridModes, item, GridRefresh) => {
                                return <Grid5W2H url={`/method5w2h/form63-${item._id}`} perms="63" />;
                            },
                            (GridModes, item, GridRefresh) => {
                                return (
                                    <div>
                                        {!GridModes.insert_mode && (
                                            <div className={'m-2'}>
                                                <GridDiario url={'/griddocument/grid63-' + item._id} perms={'63'} />
                                            </div>
                                        )}
                                    </div>
                                );
                            },
                            (GridModes, item, GridRefresh) => {
                                return <GridAnexos url={'/attachment/form63-' + item._id} perms="63" />;
                            },
                        ]}
                        onBeforeDelete={async (item) => {
                            await System.apiDelete('/griddocument/grid63-' + item._id);
                        }}
                        validationSchema={yup.object().shape({
                            data: yup.string(),
                            mudanca: yup.string(),
                            tipo: yup.string(),
                            local: yup.string(),
                            descricao: yup.string(),
                            avaliacao: yup.boolean(),
                            requisito: yup.boolean(),
                            aspecto: yup.boolean(),
                            comunicacao: yup.boolean(),
                            alteracao: yup.boolean(),
                            participantes: yup.string(),
                            dataanalise: yup.string(),
                            responsavel: yup.string(),
                        })}
                    />
                </TabPanel>
            </TabsInput>
        </div>
    );
};
