import { Form, Formik } from 'formik';
import React, { createRef, useEffect, useImperativeHandle, useMemo, useReducer, useState } from 'react';
import { useGridContext } from './GridContext';
import { GridHeadFilterMenu } from './GridHeadFilterMenu';

import fileDownload from 'js-file-download';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

import { BiEdit, BiPlusCircle, BiSearchAlt2, BiShow, BiSort, BiTrash, BiXCircle } from 'react-icons/bi';
import { FaRegWindowClose, FaSortAmountDownAlt, FaSortAmountUp } from 'react-icons/fa';
import { GrDocumentCsv, GrDocumentPdf } from 'react-icons/gr';
import { ImArrowLeft } from 'react-icons/im';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { v4 as uuidv4 } from 'uuid';
import { useSystemContext } from '../Context/SystemContext';
import { showConfirm } from './Messages';
import { Modal, ModalManager } from './Modais';

const Grid = (props, ref) => {
    const System = useSystemContext();

    useImperativeHandle(props.externalRef, () => ({
        methods,
    }));

    const reducer = (states, action) => {
        states = { ...states, ...action };
        return states;
    };

    const [states, dispatch] = useReducer(reducer, {
        countItems: 0,
        gridState: 'list',
        editingRow: null,
        inputRegister: null,
        groupColumnsItemsVisible: [],
    });

    // Tratamento de edição interna do grid
    const disableModal = props.disableModal ?? false;
    const [showEditForm, setShowEditForm] = React.useState(false);
    const [currentItem, setCurrentItem] = React.useState(null);
    const [renderedForm, setRenderedForm] = React.useState(null);
    const [searchString, setTitleFilter] = React.useState('');

    const FormTabs = props.FormTabs ?? [];

    //const pdfRef = useRef(null);

    var GridModes = {
        edit_mode: false,
        insert_mode: false,
        temporary_show: false, // APenas mostra um registro ao clicar em editar sem mostrar abas adicionais
    };

    var GridTotal = [];

    const url = props.url ?? '';
    const url_post = props.url_post ?? url;
    const url_put = props.url_put ?? url;
    const url_delete = props.url_delete ?? url;
    const url_get_all = props.url_get_all ?? url;
    const hideIndexColumn = props.hideIndexColumn ?? false;

    // permissoes

    //const [autoEdit, setAutoEdit] = React.useState(System.getAutoEdit());

    var canEdit = System.isAutoEdit() ? true : props.perms ? System.methods.getUserRule(`alterar${props.perms}`) : typeof props.canEdit === 'boolean' ? props.canEdit : true;

    var canShow = 'canShow' in props ? props.canShow : !canEdit;

    var canInsert = System.isAutoEdit() ? true : props.perms ? System.methods.getUserRule(`alterar${props.perms}`) : typeof props.canInsert === 'boolean' ? props.canInsert : true;

    var canDelete = System.isAutoEdit() ? true : props.perms ? System.methods.getUserRule(`deletar${props.perms}`) : typeof props.canDelete === 'boolean' ? props.canDelete : true;

    var preventAutoEdit = props.preventAutoEdit ?? false;

    const hideActionsButtons = props.hideActionsButtons ?? false;
    //    const clearAutoEdit = props.clearAutoEdit ?? false;

    const showSearch = props.showSearch ?? true;
    const showPDFExport = props.showPDFExport ?? true;
    const showCSVExport = props.showCSVExport ?? true;
    const showPDFLogo = props.showPDFLogo ?? true;
    const titlePDF = props.titlePDF || System.getMenuTitle(props.perms);

    const forceReloadAfterSave = props.forceReloadAfterSave ?? false;

    const GridHooks = props.GridHooks ?? [];

    const modalStyle = props.modalStyle ?? {
        content: {
            minWidth: '100px',
            minHeight: '100px',
            top: '-25%',
            //left: '25%',
            height: 'auto',
            maxHeight: '80%',
            //position: 'fixed',
            overflow: 'auto',
            //paddingLeft: '5px',
            //paddingRight : '5px',
        },
    };
    const modalAdditionalStyle = props.modalAdditionalStyle ?? {
        content: {
            minWidth: '100px',
            minHeight: '100px',
            top: '-25%',
            //left: '25%',
            height: 'auto',
            maxHeight: '80%',
            //position: 'fixed',
            overflow: 'auto',
            //paddingLeft: '5px',
            //paddingRight : '5px',
        },
    };

    const defaultSortField = props.defaultSortField ?? '';
    const defaultSortOrder = props.defaultSortOrder ?? 'asc';
    const defaultItemsPerPage = props.defaultItemsPerPage ?? 10;
    const onDrawHeader = props.onDrawHeader ?? DrawHeader;
    const onDrawBody = props.onDrawBody ?? DrawBody;
    const onSelectItem = props.onSelectItem ?? SelectItem;
    const LineDataPrint =
        props.LineDataPrint ??
        function () {
            return [];
        };
    const TotalLineDataPrint =
        props.TotalLineDataPrint ??
        function () {
            return [];
        };
    //const GridHeaders = props.GridHeaders ?? [];
    const GridHeaders = useMemo(() => {
        // Verifica se GridHeaders é um array e não está vazio
        if (!Array.isArray(props.GridHeaders) || props.GridHeaders.length === 0) {
            return [];
        }

        return props.GridHeaders.map((item) => ({
            ...item,
            type: item.type ?? 'string',
            colRef: createRef(),
        }));
    }, [props.GridHeaders]);

    const RenderForm = props.onRenderForm ?? onRenderForm;
    const RenderFormExtra = props.onRenderFormExtra ?? onRenderFormExtra;
    const RenderFormAdditional = props.onRenderFormAdditional ?? onRenderFormAdditional;

    const validationSchema = props.validationSchema ?? null;
    const onSaveRecord = props.onSaveRecord ?? internalSaveRecord;
    const onPrepareSaveRecord = props.onPrepareSaveRecord ?? internalPrepareSaveRecord;
    const initialValues = props.initialValues ?? {};
    const onAfterSave = props.onAfterSave ?? internalAfterSave;
    const onAfterEdit = props.onAfterEdit ?? internalAfterEdit;
    const ChangeGridMode = props.onChangeGridMode ?? function () { };
    const onAfterPost = props.onAfterPost ?? internalOnAfterPost;
    const onDrawFooter = props.onDrawFooter ?? DrawFooter;
    const onBeforeDelete = props.onBeforeDelete ?? function () { };
    const DeleteItem = props.onDeleteItem ?? onDeleteItem;
    const onAfterDelete = props.onAfterDelete ?? function () { };
    const AfterCloseEdit = 'onAfterCloseEdit' in props ? props.onAfterCloseEdit : _AfterCloseEdit;

    const ClickEditAction = props.onClickEditAction ?? _ClickEditAction;
    const ClickEditButton = props.onClickEditButton ?? function (item) { };
    const BeforeEditAction = props.onBeforeEditAction ?? _BeforeEditAction;
    const BeforeInsertAction = props.onBeforeInsertAction ?? _BeforeInsertAction;

    const GetAPIData = props.onGetAPIData ?? _GetAPIData;
    const GridRefresh = _GridRefresh;
    const onLoadData = props.onLoadData ?? function () { };
    const AfterLoadData = props.onAfterLoadData ?? _AfterLoadData;
    var selectedItem = null;

    // States
    const [GridData, setGridData] = React.useState([]);
    const [GridDataVisible, setGridDataVisible] = React.useState([]);

    const [sortDir, setSortDir] = React.useState(defaultSortOrder === 'asc' ? 1 : -1);
    const [sortField, setSortField] = React.useState(defaultSortField);
    const [itemsPerPage, setItemsPerPage] = React.useState(defaultItemsPerPage);
    const [CurrentPage, setCurrentPage] = React.useState(1);
    const [MaxPage, setMaxPage] = React.useState(1);

    // eslint-disable-next-line
    const [filter, setFilter] = React.useState(props?.filter ?? null);

    //const [autoEdit, setAutoEdit] = React.useState(System.getAutoEdit());

    /**
     * Proriedades do grid com acesso externo
     */

    const [property, changeProperty] = useState({
        filters: [],
        url: props.url ?? '',
        url_post: props.url_post ?? url,
        url_put: props.url_put ?? url,
        url_delete: props.url_delete ?? url,
        url_get_all: props.url_get_all ?? url,
        hideIndexColumn: props.hideIndexColumn ?? false,
        columns: useMemo(() => {
            return GridHeaders?.filter((c) => c.visible !== false) ?? [];
        }, [GridHeaders]),
        allColumns: GridHeaders,
    });

    /**
     * Metodos
     * @method GridData - Todos os dados do grid
     */
    const methods = {
        GridData: () => GridData,
        GridModes: (modes = null) => {
            if (modes) setGridModes(modes);
            return GridModes;
        },
        DeleteFilter: internalDeleteFilter,
        ActiveFilter: internalActiveFilter,
        AddFilterTag: internalAddFilterTag,
        DelFilterTag: internalDelFilterTag,
        OPDecode: internalOPDecode,
        OPList: internalOPList,
        GetSelectedItem: () => selectedItem,
    };

    async function _AfterCloseEdit(GridRefresh) {
        await GridRefresh();
    }
    function _AfterLoadData(data) {
        return data;
    }

    /**
     * Altera as propertys do componente
     * @param {*} data - Property e se valor: {property: 'valor'}
     */
    function internalSetProperty(data) {
        let addicional = {};

        // Se esta sendo alterado a busca organiza volta para pagina inicial
        //if (Object.keys(data).includes('searchString')) {
        //    addicional.currentPage = 0;
        //}

        if (data) {
            changeProperty({ ...property, ...addicional, ...data });
        }
    }

    /**
     * Traduz os operadores lógicos
     * @param {string} op - codigo do operador
     * @returns
     */
    function internalOPDecode(op) {
        switch (op) {
            case 'eq':
                return 'Igual';
            case 'ne':
                return 'Diferente';
            case 'gt':
                return 'Maior';
            case 'lt':
                return 'Menor';
            case 'gte':
                return 'Maior ou Igual';
            case 'lte':
                return 'Menor ou Igual';
            case 'in':
                return 'Contém';
            case 'nin':
                return 'Não Contém';
            case 'bt':
                return 'Entre';
            case 'nb':
                return 'Não Entre';
            default:
                return '';
        }
    }

    /**
     * Lista de operadores
     * @returns array
     */

    function internalOPList(type = 'string') {
        let list = [];

        if (['string'].includes(type)) {
            list.push({ op: 'in', title: 'Contém' });
            list.push({ op: 'nin', title: 'Não Contém' });
        }

        list.push({ op: 'eq', title: 'Igual' });
        list.push({ op: 'ne', title: 'Diferente' });

        if (['currency', 'float', 'number', 'int', 'integer', 'data', 'date', 'datetime'].includes(type)) {
            list.push({ op: 'gt', title: 'Maior' });
            list.push({ op: 'lt', title: 'Menor' });
            list.push({ op: 'gte', title: 'Maior ou Igual' });
            list.push({ op: 'lte', title: 'Menor ou Igual' });
        }

        return list;
    }

    /**
     * Adiciona uma Tag a uma coluna
     * @param {string} field
     * @param {string} tag
     */
    function internalAddFilterTag(field, tag, operator = 'in', active = true, type = 'string') {
        if (tag === '' || tag === undefined) return false;
        if (!property.filters.some((f) => f.field === field && f.tag === tag)) {
            property.filters.push({ field: field, tag: tag, active: active, op: operator, type: type });
            internalSetProperty({ filters: property.filters });
        }
    }

    /**
     * Remove uma tag de uma coluna
     * @param {string} field
     * @param {string} tag
     */
    function internalDelFilterTag(field, tag) {
        if (tag === '' || tag === undefined) return false;
        property.filters = property.filters.filter((f) => !(f.field === field && f.tag === tag));
        internalSetProperty({ filters: property.filters });
    }

    /**
     * Deleta um filtro salvo
     * @param {integer} index
     */
    function internalDeleteFilter(index) {
        // remove o item
        property.filters.splice(index, 1);

        // procura por um filtro ativo
        let active = property.filters.find((f) => f.active === true);

        // Se nao encontrou nenhum filtro ativo, usa o padrao
        if (active === undefined) active = { filter: property.allColumns.filter((c) => c.visible !== false) };

        // seta as propriedades e gera o Render
        internalSetProperty({ filters: property.filters, columns: active.filter });

        // Salvar no banco de dados
        //internalUpdateMetadata(property.filters);
    }

    /**
     * Ativa um filtro salvo
     * @param {integer} index
     */
    function internalActiveFilter(index) {
        // Pega o estado do item indicado
        let current = property.filters[index].active;

        // desativa todos
        property.filters.forEach((f) => {
            f.active = false;
        });

        // Inverte o estado do item indicado
        property.filters[index].active = !current;

        // pega os dados do filtro ativo, se houver
        let active = property.filters.find((f) => f.active === true);

        // caso não tenha encontrado nenhum filtro ativo, usa o padrao
        if (active === undefined) active = { filter: property.allColumns.filter((c) => c.visible !== false) };

        // Ativa as mudancas e gera o Render
        internalSetProperty({ filters: property.filters, columns: active.filter });

        // Salvar no banco de dados
        //internalUpdateMetadata(property.filters);
    }

    function _GridRefresh() {
        onLoadData();
        LoadData();
    }

    function setGridModes(values) {
        ChangeGridMode(values);
        for (const key in values) {
            if (GridModes.hasOwnProperty(key)) {
                GridModes[key] = values[key];
            }
        }
    }

    function onRenderFormExtra(GridModes, item, GridRefresh) {
        return null;
    }

    function _GetAPIData(url, query = []) {
        query = [...query, { filter: filter }];
        return System.apiGet(url, query);
    }

    function LoadData() {
        if (props.GridData) {
            setGridData(props.GridData);
            return;
        }
        onLoadData();
        GetAPIData(url_get_all).then((response) => {
            if (response !== undefined && response.status === 200) {
                //setGridData(await AfterLoadData(response.data));
                setGridData(response.data);
                if (System.isAutoEdit()) {
                    let found = false;
                    response.data.forEach((element) => {
                        if (element._id === System.getAutoEdit() && preventAutoEdit === false) {
                            ClickEditAction(element);
                            found = true;
                        }
                    });
                    if (!found && property.url.startsWith('/grid') && !property.url.startsWith('/griddocument')) {
                        System.apiGet(`dashboard-clean/${System.getAutoEdit()}`);
                        console.log('Registro não encontrado, excluindo', property.url, System.getAutoEdit());
                        System.delAutoEdit();
                        window.location.href = "/dashboard";
                    }
                } else {
                    dispatch({
                        gridState: 'list',
                        editingRow: null,
                    });
                }
            } else {
                console.error('Erro ao carregar dados', url_get_all, response);
                System.methods.notificationError('Erro ao carregar dados');
                return null;
            }
        });
    }

    useEffect(() => {
        LoadData();

        // eslint-disable-next-line
    }, []);

    function internalCompareDates(a, b) {
        let _a = new Date(a);
        let _b = new Date(b);
        if (_a === undefined) _a = '';
        if (_b === undefined) _b = '';
        if (_a < _b) return -1;
        if (_a > _b) return 1;
        return 0;
    }

    function deepRecursiveParseData(data, field) {
        if (field.indexOf('.') > 0) {
            let fields = field.split('.');
            let firstField = fields.shift();
            return deepRecursiveParseData(data[firstField], fields.join('.'));
        } else {
            if (!data) return '';
            return data[field];
        }
    }

    useEffect(() => {
        function internalSortData(_data = [], _sortField = sortField, _sortDir = sortDir) {
            let _a = '';
            let _b = '';

            if (_sortField === '') return _data;
            let _OrderDefine = _sortField.charAt(0);
            let _sort = sanitizesortField(_sortField);
            switch (_OrderDefine) {
                case '@':
                    return _data.sort((a, b) => {
                        _a = new Date(deepRecursiveParseData(a, _sort));
                        _b = new Date(deepRecursiveParseData(b, _sort));
                        if (_a === undefined) _a = '';
                        if (_b === undefined) _b = '';
                        if (_a < _b) return -1 * _sortDir;
                        if (_a > _b) return 1 * _sortDir;
                        return 0;
                    });
                case '#':
                    return _data.sort((a, b) => {
                        _a = deepRecursiveParseData(a, _sort);
                        _b = deepRecursiveParseData(b, _sort);
                        if (_a === undefined) _a = 0;
                        if (_b === undefined) _b = 0;
                        if (_a < _b) return -1 * _sortDir;
                        if (_a > _b) return 1 * _sortDir;
                        return 0;
                    });
                case '!':
                    return _data.sort((a, b) => {
                        _a = typeof a[_sort] === 'number' ? parseInt(a[_sort]) : 0;
                        _b = typeof b[_sort] === 'number' ? parseInt(b[_sort]) : 0;
                        if (_sortDir === 1) {
                            return _a > _b ? 1 : -1;
                        } else {
                            return _a < _b ? 1 : -1;
                        }
                    });
                default:
                    try {
                        const blankRecords = [];
                        const dataWithValues = [];
                        _data.forEach((item) => {
                            const value = deepRecursiveParseData(item, _sort);
                            if (value === undefined || value === null || value === '') {
                                blankRecords.push(item);
                            } else {
                                dataWithValues.push(item);
                            }
                        });
                        const sortedData = dataWithValues.sort((a, b) => {
                            //_a = typeof a[_sort] === 'string' ? a[_sort].toUpperCase() : a[_sort];
                            //_b = typeof b[_sort] === 'string' ? b[_sort].toUpperCase() : b[_sort];
                            _a = typeof deepRecursiveParseData(a, _sort) === 'string' ? deepRecursiveParseData(a, _sort).toUpperCase() : deepRecursiveParseData(a, _sort);
                            _b = typeof deepRecursiveParseData(b, _sort) === 'string' ? deepRecursiveParseData(b, _sort).toUpperCase() : deepRecursiveParseData(b, _sort);
                            if (_sortDir === 1) {
                                return _a > _b ? 1 : -1;
                            } else {
                                return _a < _b ? 1 : -1;
                            }
                        });
                        if (_sortDir === 1) {
                            return [...blankRecords, ...sortedData];
                        } else {
                            return [...sortedData, ...blankRecords];
                        }
                    } catch (error) {
                        console.error('❌ internalSortData', error);
                        return -1;
                    }
            }
        }

        if (GridData.length < 1) {
            setGridDataVisible([]);
            return;
        }
        let _GridData = GridData;

        // Filtro por coluna
        const filters = property.filters.filter((f) => f.active === true);
        if (filters.length > 0) {
            _GridData = GridData.filter((linha) => {
                return filters.every((filter) => {
                    // Limpar o nome do campo e recursivamente pegar ele, ex: "@campo.campo"
                    let tmp = sanitizesortField(filter.field); // remove "#", "@", "!
                    let fieldContent = deepRecursiveParseData(linha, tmp); // busca campo.subcampo, ou retorna linha[]tmp]
                    switch (filter.type) {
                        case 'date':
                            switch (filter.op) {
                                case 'eq':
                                    return internalCompareDates(fieldContent, filter.tag) === 0;
                                case 'ne':
                                    return internalCompareDates(fieldContent, filter.tag) !== 0;
                                case 'lt':
                                    return internalCompareDates(fieldContent, filter.tag) === -1;
                                case 'gt':
                                    return internalCompareDates(fieldContent, filter.tag) === 1;
                                case 'lte':
                                    return internalCompareDates(fieldContent, filter.tag) <= 0;
                                case 'gte':
                                    return internalCompareDates(fieldContent, filter.tag) >= 0;
                                default:
                                    return false;
                            }
                        case 'number':
                            let number = parseFloat(fieldContent);
                            let tag = parseFloat(filter.tag);
                            switch (filter.op) {
                                case 'eq':
                                    return fieldContent === tag;
                                case 'ne':
                                    return fieldContent !== tag;
                                case 'lt':
                                    return number < tag;
                                case 'gt':
                                    return number > tag;
                                case 'lte':
                                    return number <= tag;
                                case 'gte':
                                    return number >= tag;
                                default:
                                    return false;
                            }
                        case 'string':
                        case 'text':
                            switch (filter.op) {
                                case 'in':
                                    return fieldContent?.toLowerCase()?.includes(filter?.tag?.toLowerCase());
                                case 'nin':
                                    return !fieldContent?.toLowerCase()?.includes(filter?.tag?.toLowerCase());
                                case 'eq':
                                    return fieldContent === filter.tag;
                                case 'ne':
                                    return fieldContent !== filter.tag;
                                default:
                                    return false;
                            }
                        default:
                            return false;
                    }
                });
            });
        }

        // Filtro organico
        if (searchString !== '') {
            if (searchString.indexOf('&&') > -1) {
                let searchStringAND = searchString.split('&&');
                _GridData = GridData.filter((item) => {
                    let _item = JSON.stringify(item);
                    return _item.toLowerCase().indexOf(searchStringAND[0].toLowerCase()) > -1 && _item.toLowerCase().indexOf(searchStringAND[1].toLowerCase()) > -1;
                });
            } else if (searchString.indexOf('||') > -1) {
                let searchStringOR = searchString.split('||');
                _GridData = GridData.filter((item) => {
                    let _item = JSON.stringify(item);
                    return _item.toLowerCase().indexOf(searchStringOR[0].toLowerCase()) > -1 || _item.toLowerCase().indexOf(searchStringOR[1].toLowerCase()) > -1;
                });
            } else {
                _GridData = GridData.filter((item) => {
                    let _item = JSON.stringify(item);
                    return _item.toLowerCase().indexOf(searchString.toLowerCase()) > -1;
                });
            }
        }

        let _firstItem = (CurrentPage - 1) * itemsPerPage;
        if (_firstItem > _GridData.length) {
            _firstItem = 0;
            setCurrentPage(1);
        }
        setMaxPage(Math.ceil(_GridData.length / itemsPerPage));
        _GridData = internalSortData(_GridData, sortField, sortDir);
        const AfterDataLoadEvent = async () => {
            _GridData = await AfterLoadData(_GridData);
        };

        // chama o evento apos carregar e preparar os dados
        AfterDataLoadEvent();

        let _GriDataVisible = [];
        try {
            _GridData.forEach((item, index) => {
                if (index >= _firstItem && index < _firstItem + itemsPerPage) {
                    _GriDataVisible.push(item);
                }
            });
        } catch (error) {
            console.error('❌ _GridData.forEach', error);
        }
        setGridDataVisible(_GriDataVisible);

        // eslint-disable-next-line
    }, [GridData, CurrentPage, itemsPerPage, sortField, sortDir, searchString, property]);

    function onSortColumn(column) {
        if (sortField === column) {
            setSortDir(sortDir * -1);
        } else {
            setSortField(column);
            setSortDir(1);
        }
        return;
    }

    const ExportToPDF = async () => {
        const doc = new jsPDF('p', 'pt');
        doc.setFontSize(8);

        let dados = [];
        if (searchString !== '') {
            dados = GridDataVisible;
        } else {
            dados = GridData;
        }

        const formatedData = dados.map((item, index) => {
            TotalLineDataPrint(item, GridTotal);
            let LineData = LineDataPrint(item);
            if (!LineData) return null;
            return LineData.map((coluna, index) => {
                return typeof coluna === 'string' ? coluna.split('\n').join(' ') : '';
            });
        });

        const logoResponse = await System.apiGet('/company/' + System.property.user.companyId);
        const company = logoResponse.data;
        const logo = showPDFLogo ? await System.methods.apiGetImg(company.logotipo) : null;
        const date = new Date().toLocaleDateString();
        const time = new Date().toLocaleTimeString();
        const url = window.location.href;

        const addHeader = (doc) => {
            if (titlePDF) doc.text(`${titlePDF}`, 40, 60);
            if (logo) doc.addImage(logo, 'JPEG', doc.internal.pageSize.width - 140, 10, 100, 50);
        };

        const addFooter = (doc, pageNumber, maxPage) => {
            doc.text(`${url}`, 40, 820);
            doc.text(`${date} ${time}`, doc.internal.pageSize.width / 2, 820, { align: 'center' });
            doc.text(`Página ${pageNumber}/${maxPage}`, doc.internal.pageSize.width - 40, 820, { align: 'right' });
        };

        const columns = GridHeaders.map((item) => {
            return { header: item.title, dataKey: item.field };
        });

        doc.autoTable({
            columns: columns,
            body: formatedData,
            startY: 65, // Espaço para o cabeçalho
            margin: { top: 65, bottom: 30 },
            styles: { cellPadding: 5, fontSize: 6, overflow: 'linebreak' },
            headStyles: { fillColor: [200, 200, 200] },
        });

        // Adiciona cabeçalho e rodapé ao resultado final
        const totalPages = doc.internal.getNumberOfPages();
        for (let i = 1; i <= doc.internal.getNumberOfPages(); i++) {
            doc.setPage(i);
            addHeader(doc);
            addFooter(doc, i, totalPages);
        }

        doc.save('eqsgt.pdf');
    };

    function ExportToCSV() {
        let _csv = '';
        let _col = GridHeaders;
        if (!hideIndexColumn) {
            _csv += '"#";';
        }
        for (let i = 0; i < _col.length; i++) {
            if (_col[i].title === 'Ações') continue;
            _csv += '"' + _col[i].title + '";';
        }
        _csv += '\n';

        let dados = [];
        if (searchString !== '') {
            dados = GridDataVisible;
        } else {
            dados = GridData;
        }
        let indice = 0;
        dados.forEach((item) => {
            if (!hideIndexColumn) {
                indice++;
                _csv += '"' + indice + '";';
            }
            let LineData = LineDataPrint(item);
            LineData.forEach((coluna) => {
                let celula = '';
                if (typeof coluna === 'string') {
                    if (coluna.charAt(0) === '#') {
                        celula = coluna.split(':')[1];
                    } else {
                        celula = coluna.split('\n').join(' ');
                    }
                }
                if (celula === undefined) celula = '';
                _csv += `"${celula.replace(/"/g, '""')}";`;
            });
            _csv += '\n';
        });
        _csv = '\uFEFF' + _csv; // Adiciona o BOM (Byte Order Mark) para especificar a codificação UTF-8
        var blob = new Blob([_csv], { type: 'text/csv;charset=utf-8;' });
        var url = URL.createObjectURL(blob);
        var link = document.createElement('a');
        link.setAttribute('href', url);
        link.setAttribute('download', 'eqsgt.csv');
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }
    function internalAfterSave(item) {
        return item;
    }

    function RenderSearch() {
        return (
            <div className="inline-flex">
                <BiSearchAlt2 className="h-8 cursor-pointer hover:text-blue-800" />
                <input
                    className="w-48 px-1 mt-1 mb-1 text-sm bg-white border border-gray-800 rounded focus:outline-none"
                    type="text"
                    placeholder="Pesquisar"
                    value={searchString}
                    onChange={(e) => {
                        setTitleFilter(e.target.value);
                    }}
                />
                <BiXCircle className="h-8 ml-1 cursor-pointer hover:text-blue-800" onClick={() => setTitleFilter('')} title="Limpar Pesquisa" />
            </div>
        );
    }

    function onRenderForm(GridModes) {
        return <div>onRenderForm</div>;
    }

    function onRenderFormAdditional(GridModes) {
        return <div>onRenderForm</div>;
    }

    function _BeforeEditAction(item) {
        return item;
    }

    function DrawActionsButtons(item) {
        if ((!canEdit && !canDelete && !canShow) || hideActionsButtons) return null;
        return (
            <div className="inline-flex text-xl">
                {canShow && <BiShow className="mt-1 cursor-pointer hover:text-blue-800" title="Visualizar" onClick={() => ClickEditAction(BeforeEditAction(item))} />}
                {canEdit && (
                    <BiEdit
                        className="mt-1 cursor-pointer hover:text-blue-800"
                        title="Editar"
                        onClick={() => {
                            ClickEditAction(BeforeEditAction(item));
                        }}
                    />
                )}
                {canDelete && <BiTrash className="mt-1 cursor-pointer hover:text-red-800" title="Excluir" onClick={() => ClickDeleteAction(item)} />}
            </div>
        );
    }
    function ClickDeleteAction(item) {
        DeleteItem(item);
    }

    function _ClickEditAction(item) {
        setGridModes({
            edit_mode: true,
            insert_mode: false,
        });
        selectedItem = item;
        setCurrentItem(item);
        if (disableModal) {
            setRenderedForm(_renderForm(false));
            setShowEditForm(true);
        } else {
            OpenModalForm();
        }
        ClickEditButton(item);
    }
    function ClickEditActionAdditional(item) {
        selectedItem = item;
        setGridModes({
            edit_mode: true,
            insert_mode: false,
        });
        onSelectItem(item);
        OpenModalFormAdditional();
    }

    async function onDeleteItem(item) {
        showConfirm(
            'Excluir este registro?',
            async () => {
                await onBeforeDelete(item);
                System.apiDelete(url_delete + '/' + item._id).then(async (response) => {
                    if (response.status === 200) {
                        if (GridData.length === 1) {
                            setGridData([]);
                        } else {
                            setGridData(GridData.filter((x) => x._id !== item._id));
                        }
                        onAfterDelete(item);
                        System.methods.notificationInformation('Registro excluído com sucesso!');
                    } else {
                        System.methods.notificationError(response.data.message ?? 'Erro ao excluir registro!');
                    }
                });
            },
            () => {
                AfterCloseEdit(_GridRefresh);
                System.setAutoEdit(false);
                ModalManager.close();
            },
            'Excluir',
            'Cancelar'
        );
    }

    function DrawHeader() {
        GridHeaders.map((item, index) => {
            if (item.totalize)
                GridTotal.push({
                    field: item.field,
                    totalTitle: item.totalTitle,
                    value: 0,
                    totalize: item?.totalize === true,
                });
            return true;
        });
        return (
            <>
                <tr className="bg-gray-200">
                    {!hideIndexColumn && (
                        <th className="px-4 border-b border-r border-gray-300">
                            <span className="text-gray-700" key={uuidv4()}>
                                #
                            </span>
                        </th>
                    )}
                    {GridHeaders.map((item, index) => {
                        return (
                            <th key={index} className={`${item.className} border-b border-r border-gray-300`} ref={item.colRef}>
                                <div className={` inline-flex w-full  `}>
                                    <div
                                        className={`mt-1 inline-flex w-full ${item.sort ? 'cursor-pointer hover:text-blue-600' : null} `}
                                        onClick={() => {
                                            if (item.sort) {
                                                onSortColumn(item.field);
                                            }
                                        }}
                                    >
                                        {item.field === sortField ? (
                                            sortDir > 0 ? (
                                                <FaSortAmountDownAlt className="mt-1" title="Crescente" />
                                            ) : (
                                                <FaSortAmountUp className="mt-1" title="Decrecente" />
                                            )
                                        ) : null}
                                        {item.field !== sortField && item.sort ? <BiSort className="mt-1" /> : null}
                                        {item.title}
                                    </div>
                                    {item.filter !== false && (
                                        <div className="mt-1 ml-auto cursor-pointer ">
                                            <GridHeadFilterMenu col={item} />
                                        </div>
                                    )}
                                </div>
                            </th>
                        );
                    })}
                    {canEdit || canDelete || canShow ? DrawActionsHeader() : null}
                </tr>
            </>
        );
    }

    function DrawActionsHeader() {
        if ((!canEdit && !canDelete && !canShow) || hideActionsButtons) return null;
        return <th className="text-sm font-normal text-center border-b border-l border-gray-300">Ações</th>;
    }

    function sanitizesortField(field) {
        if (field[0] === '@' || field[0] === '#' || field[0] === '!') {
            return field.substring(1);
        }
        return field;
    }

    const DrawCells = (data, item) => {
        if (!data) return <td></td>;
        return data.map((cell, index) => {
            const emptyCell = <td key={uuidv4()} className={` ${index === 0 ? '' : 'border-l'} ${GridHeaders[index]?.className}`}></td>;
            if (!cell) return emptyCell;
            let fieldType = GridHeaders[index].type;
            if (typeof cell === 'object' && fieldType === 'string') {
                return (
                    <td key={uuidv4()} className={` ${index === 0 ? '' : 'border-l'} ${GridHeaders[index]?.className}`}>
                        {cell}
                    </td>
                );
            } else {
                switch (fieldType) {
                    case 'fileview':
                        if (typeof cell === 'string' && cell.length === 24) {
                            cell = { fileid: cell };
                        } else {
                            if (!cell.fileid) return emptyCell;
                        }
                        return (
                            <td key={uuidv4()} className={` ${index === 0 ? '' : 'border-l'} ${GridHeaders[index]?.className}`}>
                                {cell.fileid && cell.fileid.length === 24 && (
                                    <button
                                        key={index}
                                        onClick={() => {
                                            if (typeof cell === 'object' && cell.fileid && cell.fileid.length === 24) _viewFile(cell.fileid);
                                            if (cell.onView) cell.onView();
                                        }}
                                        className={'rounded-md border border-gray-500 bg-gray-300 px-2 hover:bg-gray-600 hover:text-white'}
                                    >
                                        Visualizar
                                    </button>
                                )}
                            </td>
                        );
                    default:
                        return (
                            <td key={uuidv4()} className={` ${index === 0 ? '' : 'border-l'} ${GridHeaders[index]?.className}`}>
                                {ExtractHTML(cell, item)}
                            </td>
                        );
                }
            }
        });
    };

    function DrawBody() {
        if (GridDataVisible.length === 0)
            return (
                <tr key={uuidv4()} className="bg-gray-200">
                    <td colSpan={100} className="pl-2 font-semibold text-center text-red-600 bg-white border">
                        Nenhum registro a ser exibido.
                    </td>
                </tr>
            );
        return GridDataVisible.map((item, index) => {
            if (item === undefined)
                return (
                    <tr key={uuidv4()} className="bg-gray-200">
                        <td colSpan={100} className="pl-2 bg-white border">
                            Nenhum registro.
                        </td>
                    </tr>
                );
            TotalLineDataPrint(item, GridTotal);
            let LineData = LineDataPrint(item);
            if (!LineData) return null;
            return (
                <tr
                    key={uuidv4()}
                    onClick={(event) => {
                        selectedItem = item[index];
                        onSelectItem(item);
                        setCurrentItem(item);
                    }}
                    className="border-b border-r-0 border-gray-300 cursor-pointer hover:bg-gray-200"
                >
                    {!hideIndexColumn && (
                        <td className="text-center border-b border-r border-gray-200">
                            <span className="text-gray-700 " key={uuidv4()}>
                                {(CurrentPage - 1) * itemsPerPage + index + 1}
                            </span>
                        </td>
                    )}
                    {DrawCells(LineData, item)}
                    {canEdit || canDelete || canShow ? <td className="text-center border-l border-r-0">{DrawActionsButtons(item)}</td> : null}
                </tr>
            );
        });
    }
    function onDrawTotals() {
        if (GridTotal.length === 0) return null;
        let tt = [<td className="px-4 py-2 text-left border-b border-l border-gray-300"></td>];
        GridHeaders.map((item, index) => {
            return GridTotal.map((total) => {
                if (total.field === item.field) {
                    tt.push(
                        <td key={index} className="font-bold border-l border-gray-300">
                            {total.totalTitle} {total.value}
                        </td>
                    );
                } else {
                    tt.push(<td key={index} className="border-l border-gray-300"></td>);
                }
                return null;
            });
        });
        return <tr className="bg-gray-100 ">{[tt, <td className="border-l border-r border-gray-300"></td>]}</tr>;
    }
    async function _viewFile(file) {
        const f = await System.apiGetFileInfo(file);
        const extension = f.filename.split('.').pop().toLowerCase(); // Obtém a extensão do arquivo

        const allowedExtensions = ['ppt', 'pptx', 'doc', 'docx', 'xls', 'xlsx'];
        if (allowedExtensions.includes(extension)) {
            window.open(`https://docs.google.com/gview?url=${encodeURIComponent(System.getBaseUrl())}/file/${file}`, '_blank');
        } else {
            const arq = await System.apiGetFile(file);
            const ufile = new Blob([arq], { type: f.metadata.contentType });
            // Outra lógica para exibir outros tipos de arquivos
            window.open(URL.createObjectURL(ufile));
        }
    }

    async function _downloadFile(file) {
        const fileinfo = await System.apiGetFileInfo(file);
        const Ext = fileinfo.filename.split('.').pop();
        System.apiGetRawFile(file).then(async (response) => {
            if (response.status === 200) {
                fileDownload(response.data, file + '.' + Ext);
            }
        });
    }
    function ExtractHTML(text, activeItem) {
        if (!text || text === undefined) return '';
        if (typeof text === 'number') return text.toString();
        let btnclass = 'px-2 bg-gray-300 border border-gray-500 rounded-md hover:bg-gray-600 hover:text-white';
        if (text.charAt(0) !== '#') return text;

        let _text = text.substr(1);
        let lista = _text.split('|');
        return lista.map((item, index) => {
            let [command, param] = item.split('=');
            switch (command) {
                case 'btnEditAdditionalForm':
                    return (
                        <div key={index} onClick={() => ClickEditActionAdditional(activeItem)} className={btnclass}>
                            {param}
                        </div>
                    );
                case 'view':
                    if (param.length !== 24) return null;
                    return (
                        <button key={index} onClick={() => _viewFile(param)} className={btnclass}>
                            Visualizar
                        </button>
                    );
                case 'link':
                case 'download':
                    if (param.length !== 24) return null;
                    return (
                        <button key={index} onClick={() => _downloadFile(param)} className={btnclass}>
                            Baixar
                        </button>
                    );
                case 'checkbox': // checkbox=true/false:funcao:parametros
                    let [checked, hookName, par] = param.split(':');
                    let hookParams = [GridData, ...par.split(',')];
                    return (
                        <input
                            key={index}
                            type="checkbox"
                            className="form-checkbox"
                            defaultChecked={checked === 'true'}
                            value={checked === 'true'}
                            onClick={(e) => {
                                if (hookName) {
                                    GridHooks[hookName](...hookParams);
                                }
                            }}
                        />
                    );
                case 'img':
                    return <img src={System.methods.apiGetImg(param)} alt={''} />;
                case 'icon':
                    return <i className={param} />;
                case 'bold':
                    return <b>{param}</b>;
                case 'color':
                    let [color, text] = param.split(':');
                    return (
                        <span style={{ color: color }} className="font-bold" key={uuidv4()}>
                            {text}
                        </span>
                    );
                default:
                    return <></>;
            }
        });
    }

    function DrawPagination() {
        const goPage = (p = 0) => {
            let colors = p === CurrentPage ? 'bg-amarelo-eq text-white' : 'bg-white text-black';
            return (
                <div
                    key={uuidv4()}
                    className={`mt-1 mr-1 cursor-pointer rounded border border-gray-700 px-1 text-xs hover:bg-amarelo-eq hover:text-white ${colors}`}
                    onClick={() => {
                        setCurrentPage(p);
                    }}
                >
                    {p}
                </div>
            );
        };

        let pages = [];
        if (MaxPage > 1) {
            if (CurrentPage <= 3) {
                // Corrigir a repetição de páginas
                for (let i = 1; i <= Math.min(5, MaxPage); i++) {
                    pages.push(goPage(i));
                }
            } else if (CurrentPage > 3 && CurrentPage < MaxPage - 2) {
                pages.push(goPage(1));
                pages.push(
                    <div key={uuidv4()} className="mt-1 mr-1 text-xs">
                        ...
                    </div>
                );
                for (let i = CurrentPage - 2; i <= CurrentPage + 2; i++) {
                    pages.push(goPage(i));
                }
            } else {
                pages.push(goPage(1));
                pages.push(
                    <div key={uuidv4()} className="mt-1 mr-1 text-xs">
                        ...
                    </div>
                );
                for (let i = Math.max(1, MaxPage - 4); i <= MaxPage; i++) {
                    pages.push(goPage(i));
                }
            }
        } else {
            return null;
        }

        return (
            <div className="inline-flex pl-1 grow-0" key={uuidv4()}>
                <div
                    className="px-1 mt-1 mr-1 text-xs bg-white border border-gray-700 rounded cursor-pointer hover:bg-amarelo-eq hover:text-white"
                    onClick={() => {
                        if (CurrentPage > 1) setCurrentPage(CurrentPage - 1);
                    }}
                >
                    Anterior
                </div>
                {pages}
                <div
                    key={uuidv4()}
                    className="px-1 mt-1 text-xs bg-white border border-gray-700 rounded cursor-pointer hover:bg-amarelo-eq hover:text-white"
                    onClick={() => {
                        if (CurrentPage < MaxPage) setCurrentPage(CurrentPage + 1);
                    }}
                >
                    Próxima
                </div>
            </div>
        );
    }

    function DrawFooter() {
        return (
            <tr className="bg-gray-200 border-t-2 border-l border-gray-200">
                <td className="text-xs" colSpan={GridHeaders.length + 2}>
                    <div className="flex mb-1 text-xl">
                        <div className="inline-flex pl-1 grow-0">{DrawPagination()}</div>
                        <div className="grow"></div>
                        <div className="text-xs grow-0">
                            Itens:
                            <select
                                className="mt-1 ml-2 mr-1"
                                value={itemsPerPage}
                                onChange={(e) => {
                                    setItemsPerPage(parseInt(e.target.value));
                                    setCurrentPage(1);
                                }}
                            >
                                <option value="10">10</option>
                                <option value="20">20</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                                <option value="200">200</option>
                                <option value="99999">Todos</option>
                            </select>
                        </div>
                    </div>
                </td>
            </tr>
        );
    }

    function internalPrepareSaveRecord(values, oldValues) {
        if (values.arquivo?.name) {
            let newForm = new FormData();
            for (let key in values) {
                newForm.append(key, values[key]);
            }
            return newForm;
        }
        return values;
    }
    function internalAfterEdit(values, oldValues, GridRefresh) {
        return true;
    }
    function internalOnAfterPost(values, GridRefresh) {
        return true;
    }
    async function internalSaveRecord(values) {
        var record = onPrepareSaveRecord(values);
        if (GridModes.insert_mode) {
            await System.apiPost(url_post, record).then(async (response) => {
                if (response.status === 200) {
                    onAfterSave(record);
                    onAfterPost(values, _GridRefresh);
                    System.methods.notificationSuccess('Salvo com sucesso!');
                    if (forceReloadAfterSave) {
                        LoadData();
                    } else {
                        setGridData([...GridData, response.data.raw]);
                    }
                } else {
                    System.methods.notificationError(response.data.message);
                }
            });
        }
        if (GridModes.edit_mode) {
            await System.apiPut(url_put + '/' + values._id, record).then(async (response) => {
                if (response.status === 200) {
                    let oldValues = GridData.find((x) => x._id === values._id);
                    onAfterEdit(values, oldValues, _GridRefresh);
                    onAfterSave(record);
                    System.methods.notificationSuccess('Alterado com sucesso!');
                    // Atualiza o Grid
                    let gd = GridData.map((item) => {
                        if (item._id === response.data.raw._id) item = response.data.raw;
                        return item;
                    });
                    if (forceReloadAfterSave || gd.length < 1) {
                        LoadData();
                    } else {
                        setGridData(gd);
                    }
                } else {
                    System.methods.notificationError(response.data.message);
                }
            });
        }
        //
        setGridModes({
            edit_mode: false,
            insert_mode: false,
        });
    }
    function _renderForm(additional = false) {
        const onRequestClose = () => {
            onLoadData();
            return false;
        };
        let itemAtual = {};
        if (!GridModes.insert_mode) {
            itemAtual = selectedItem ?? currentItem;
        } else {
            itemAtual = selectedItem;
        }
        if (FormTabs.length === 0 || GridModes.insert_mode || GridModes.temporary_show) {
            return (
                <Formik
                    initialValues={itemAtual}
                    validateOnChange={true}
                    validateOnBlur={true}
                    enableReinitialize={true}
                    validationSchema={validationSchema}
                    onSubmit={(values, { setSubmitting }) => {
                        if (!canEdit && !canInsert) {
                            System.methods.notificationError('Usuário sem permissão para alterar dados..');
                            return;
                        }
                        setTimeout(() => {
                            onSaveRecord(values, GridModes);
                            if (disableModal) {
                                setShowEditForm(false);
                            } else {
                                AfterCloseEdit(_GridRefresh);
                                System.setAutoEdit(false);
                                ModalManager.close();
                            }
                            dispatch({
                                gridState: 'list',
                                editingRow: null,
                            });

                            setSubmitting(false);
                        }, 400);
                    }}
                >
                    {({ values, errors, setErrors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) => (
                        <div>
                            <Form>
                                {additional ? RenderFormAdditional(GridModes, setFieldValue, values) : RenderForm(GridModes, setFieldValue, values, GridRefresh)}
                                <div className="flex justify-end gap-4 pt-4 pb-3 pr-4 mt-4 border-t">
                                    {!GridModes.temporary_show && (
                                        <button
                                            type="submit"
                                            className="w-1/3 h-8 rounded-md shadow-md border-1 bg-botao-primario text-botao-primario-fonte hover:bg-botao-primario-hover hover:text-botao-primario-fonte"
                                        >
                                            {GridModes.insert_mode ? 'Salvar' : 'Alterar'}
                                        </button>
                                    )}
                                    <button
                                        type="button"
                                        className="w-1/3 h-8 rounded-md shadow-md bg-botao-secundario text-botao-secundario-fonte hover:border-botao-secundario-fonte-hover hover:bg-botao-secundario-hover"
                                        onClick={() => {
                                            setGridModes({
                                                edit_mode: false,
                                                insert_mode: false,
                                            });
                                            if (disableModal) {
                                                setShowEditForm(false);
                                            } else {
                                                onRequestClose();
                                                AfterCloseEdit(_GridRefresh);
                                                System.setAutoEdit(false);
                                                ModalManager.close();
                                            }
                                        }}
                                    >
                                        Cancelar
                                    </button>
                                </div>
                            </Form>
                            {FormTabs.length === 0 ? RenderFormExtra(GridModes, itemAtual, GridRefresh) : null}
                        </div>
                    )}
                </Formik>
            );
        } else {
            return (
                <div>
                    <Tabs>
                        <TabList>
                            {FormTabs.map((tab, index) => (
                                <Tab key={index} title={tab.title}>
                                    {tab()}
                                </Tab>
                            ))}
                        </TabList>
                        <TabPanel key={uuidv4()}>
                            <Formik
                                key={uuidv4()}
                                initialValues={itemAtual}
                                validateOnChange={true}
                                validateOnBlur={true}
                                enableReinitialize={true}
                                validationSchema={validationSchema}
                                onSubmit={(values, { setSubmitting }) => {
                                    if (!canEdit) {
                                        System.methods.notificationError('Usuário sem permissão para alterar dados..');
                                        return;
                                    }

                                    setTimeout(() => {
                                        onSaveRecord(values, GridModes);
                                        if (disableModal) {
                                            setShowEditForm(false);
                                        } else {
                                            onRequestClose();
                                            AfterCloseEdit(_GridRefresh);
                                            ModalManager.close();
                                        }
                                        AfterCloseEdit(GridRefresh);
                                        System.setAutoEdit(false);
                                        setSubmitting(false);
                                    }, 400);
                                }}
                            >
                                {({ values, errors, setErrors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) => (
                                    <div>
                                        <Form>
                                            {additional ? RenderFormAdditional(GridModes, setFieldValue, values) : RenderForm(GridModes, setFieldValue, values)}
                                            <div className="flex justify-end gap-4 pt-4 pb-3 pr-4 mt-4 border-t">
                                                <button
                                                    type="submit"
                                                    className="w-1/3 h-8 rounded-md shadow-md border-1 bg-botao-primario text-botao-primario-fonte hover:bg-botao-primario-hover hover:text-botao-primario-fonte"
                                                >
                                                    {GridModes.insert_mode ? 'Salvar' : 'Alterar'}
                                                </button>
                                                <button
                                                    type="button"
                                                    className="w-1/3 h-8 rounded-md shadow-md bg-botao-secundario text-botao-secundario-fonte hover:border-botao-secundario-fonte-hover hover:bg-botao-secundario-hover"
                                                    onClick={() => {
                                                        setGridModes({
                                                            edit_mode: false,
                                                            insert_mode: false,
                                                        });
                                                        if (disableModal) {
                                                            setShowEditForm(false);
                                                        } else {
                                                            onRequestClose();
                                                            AfterCloseEdit(_GridRefresh);
                                                            System.setAutoEdit(false);
                                                            ModalManager.close();
                                                        }
                                                    }}
                                                >
                                                    Cancelar
                                                </button>
                                            </div>
                                        </Form>
                                    </div>
                                )}
                            </Formik>
                        </TabPanel>
                        {RenderFormExtra.map((tab) => (
                            <TabPanel key={uuidv4()}>{tab(GridModes, itemAtual, GridRefresh)}</TabPanel>
                        ))}
                    </Tabs>
                </div>
            );
        }
    }
    function _BeforeInsertAction(item) {
        return item;
    }
    function renderPlusButton() {
        if (hideActionsButtons) return null;
        return (
            <button
                className="h-8 text-3xl text-gray-800 hover:text-gray-100"
                title="Incluir novo registro"
                onClick={() => {
                    setGridModes({
                        edit_mode: false,
                        insert_mode: true,
                    });
                    selectedItem = BeforeInsertAction(initialValues);
                    if (disableModal) {
                        setRenderedForm(_renderForm(false));
                        setShowEditForm(true);
                    } else {
                        OpenModalForm();
                    }
                }}
            >
                <BiPlusCircle className="mr-2" />
            </button>
        );
    }

    function OpenModalForm() {
        const onRequestClose = () => {
            return false;
        };
        ModalManager.open(
            <Modal name="form" onRequestClose={() => onRequestClose} style={modalStyle}>
                <div>
                    <div
                        className="absolute mt-1 text-sm text-gray-500 cursor-pointer right-1 hover:text-black"
                        onClick={() => {
                            AfterCloseEdit(_GridRefresh);
                            System.setAutoEdit(false);
                            ModalManager.close();
                        }}
                    >
                        <FaRegWindowClose />
                    </div>
                    {_renderForm(false)}
                </div>
            </Modal>
        );
    }
    function OpenModalFormAdditional() {
        const onRequestClose = () => {
            return false; // Determina se aceita fechar o modal de forma normal
        };
        ModalManager.open(
            <Modal name="formAdditional" onRequestClose={() => onRequestClose} style={modalAdditionalStyle}>
                <div>{_renderForm(true)}</div>
            </Modal>
        );
    }

    function SelectItem(item) { }

    // Main
    return (
        <div className="text-sm">
            <useGridContext.Provider value={{ GridData, GridModes, property, methods, states }}>
                {!showEditForm && (
                    <div>
                        <div className="flex rounded-t bg-amarelo-eq">
                            <div className="inline-flex pl-1 text-xl rounded-t grow">
                                {showSearch && RenderSearch()}
                                {showCSVExport && (
                                    <GrDocumentCsv className="h-8 ml-2 cursor-pointer hover:text-blue-800 hover:shadow" onClick={() => ExportToCSV()} title="Exportar para CSV" />
                                )}
                                {showPDFExport && (
                                    <GrDocumentPdf className="h-8 ml-2 cursor-pointer hover:text-blue-800 hover:shadow" onClick={() => ExportToPDF()} title="Exportar para PDF" />
                                )}
                            </div>
                            {canInsert ? renderPlusButton() : null}
                        </div>
                        <div className="flex bg-white">
                            <table className="w-full h-full border border-gray-200">
                                <thead>{onDrawHeader()}</thead>
                                <tbody>
                                    {onDrawBody()}
                                    {onDrawTotals()}
                                </tbody>
                                <tfoot>{onDrawFooter()}</tfoot>
                            </table>
                        </div>
                    </div>
                )}
                {showEditForm && (
                    <div>
                        {disableModal && (
                            <div className="w-full mt-2 mb-1">
                                <button
                                    type="button"
                                    className="px-2 mb-2 ml-2 rounded-md shadow-md w-30 h-7 bg-botao-secundario text-botao-secundario-fonte hover:border-botao-secundario-fonte-hover hover:bg-botao-secundario-hover"
                                    onClick={() => {
                                        setGridModes({
                                            edit_mode: false,
                                            insert_mode: false,
                                        });
                                        setShowEditForm(false);
                                        System.setAutoEdit(false);
                                    }}
                                >
                                    <div className="flex justify-center">
                                        <ImArrowLeft className="mt-1 mr-2" />
                                        <div>Voltar</div>
                                    </div>
                                </button>
                            </div>
                        )}

                        {renderedForm}
                    </div>
                )}
            </useGridContext.Provider>
        </div>
    );
};

export default Grid;
